<template>
    <div class="px-4">
        <v-container>
            <v-row>
                <v-col class="subdued">Occupancy Threshold</v-col>
                <v-col class="text-right">&#8804; {{rate_campaign.occupancy_threshold}}%</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col class="subdued">Rate</v-col>
                <v-col class="text-right">{{rate_campaign.rate}}% of Sell Rate</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col class="subdued">Weekdays</v-col>
                <v-col class="text-right">{{rate_campaign.days_overrides.map(d => {
                        let day = CALENDAR.DAYS_LIST.find(c => c.value === d);
                        return day.name
                    }).join(', ') }}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col class="subdued">Months</v-col>
                <v-col class="text-right">{{rate_campaign.months_overrides.map(d => {
                        let month = CALENDAR.MONTHS_LIST.find(c => c.value === d);
                        return month.name
                    }).join(', ')}}</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
                <v-col class="subdued">Value Tiers</v-col>
                <v-col class="text-right">{{rate_campaign.value_tiers.map(t => $options.filters.capitalize(t)).join(', ')}}</v-col>
            </v-row>
            <v-divider></v-divider>
             <!-- <v-row>
                <v-col class="subdued">Conditions</v-col>
                <v-col class="text-right">{{rate_campaign.conditions.map(c => conditions[c]).join(', ')}}</v-col>
            </v-row>
            <v-divider></v-divider> -->
            <v-row>
                <v-col class="subdued">Rent Increate Plan</v-col>
                <v-col class="text-right">{{rate_campaign.RentManagementPlan.name}}</v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

 import CALENDAR from '@/constants/calendar.js';
export default {
    name: 'rateCampaingSettings',
    data() {
        return {
            CALENDAR: {},
        };
    },
    props: ['rate_campaign'],
     created(){
        this.CALENDAR = CALENDAR;
    },
    filters:{
            capitalize(value){
            if (!value) return '';
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    }, 

}
</script>

<style scoped>
 
.subdued {
    color: #637381;
}
</style>
