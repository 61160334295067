<template>
  <v-select
    rounded
    hide-details
    :items="properties"
    item-value="id"
    background-color="white"
    outlined
    dense
    :menu-props="{ bottom: true, offsetY: true }"
    v-model="property"
    height="40"
    id="property"
    label="Select Rent Change"
  >
    <template v-slot:append>
      <v-row class="d-flex align-center pl-2" style="cursor: pointer">
        <span class="mt-0 mr-2 pl-2 hb-larger-font-size hb-text-lighter"
          >view</span
        >
        <svg
          class="mr-3"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="7.5"
            stroke="#E0E3E8"
          />
          <path
            d="M8.17709 10.623C7.73596 10.623 7.51097 11.1527 7.81725 11.4701L11.6402 15.4329C11.8368 15.6367 12.1632 15.6367 12.3598 15.4329L16.1828 11.4701C16.489 11.1527 16.264 10.623 15.8229 10.623H8.17709Z"
            fill="#677380"
          />
        </svg>
      </v-row>
    </template>
    <template v-slot:selection="data">
      <div class="hb-input-overflow d-flex align-center">
        <hb-icon color="#101318" class="pr-1">mdi-facility-custom-2</hb-icon>
        <span v-if="data.item.number"  class="pr-1">{{ data.item.number }} -</span>
        <span>
          {{ data.item.Address | formatAddress('-', true) }} 
        </span>
      </div>
    </template>
    <template v-slot:item="data">
      <div class="hb-input-overflow d-flex align-center">
        <hb-icon color="#101318" class="pr-1">mdi-facility-custom-2</hb-icon>
        <span v-if="data.item.number" class="pr-1">
          {{ data.item.number }} -
        </span>
        {{ data.item.Address | formatAddress('-', true) }} 
      </div>
    </template>
  </v-select>
</template>
<script>
import { mapGetters } from "vuex";
import methods from "../../../assets/api/methods";
export default {
  name: "PropertySelector",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
    }),
    property: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  }
};
</script>