<template>
    <div class="rate-plans-wrapper my-4">
        

        <hb-notification
            
                v-model="notification"
                v-if="notification"
                title="Note:"
                type="caution"
                class="mb-2"
            > 
                <ul>
                    <li>Libraries are set up on a company level. Changes may affect multiple properties</li>
                    <li>Changes made to any Rate Campaign will only take effect after midnight</li>
                </ul>
            </hb-notification>

            <v-expansion-panels v-model="expansion_panel">
                <hb-expansion-panel guidance>
                    <template v-slot:title>How to use Rate Campaigns</template>
                     <template v-slot:content>
                
                    </template>
                </hb-expansion-panel>
        
            </v-expansion-panels>


         <!-- skeleton loader while fetching the initial data -->
        <span class="rate-card-wrap pl-3 pt-8" v-if="initialDataLoading">
            <span v-for="n in 8" :key="n" class="loader-card">
                <v-skeleton-loader
                type="card-heading, list-item-two-line"
                ></v-skeleton-loader>
                <v-skeleton-loader type="image, image"></v-skeleton-loader>
            </span>
        </span>

       
        
        

         <template v-else>
        
            <hb-data-table-header>
               
                <template v-slot:description>
                    
                     <HbTextField
                        style="max-width: 300px;"
                        search
                        v-model="search"
                        
                        placeholder="Search"
                    />
                </template>
                <template v-slot:actions>
                    <hb-btn  v-if="hasRolePermission('manage_rate_plan')" small color="secondary" @click="showManageRateCampaign = true">
                        <v-icon small >mdi-plus</v-icon> Add New Rate Campaign
                    </hb-btn>
                </template>
            </hb-data-table-header>
            <hb-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
            >   


                <template v-slot:no-data>
                         <!-- component which is displayed when no rate campagins available, has option for adding new rate campaign -->
                    <EmptyStateContainer
                        :message="'There are no rate campaigns found.'"
                        showBtn
                        btnTxt="Add Rate Campaign"
                        @clicked="showManageRateCampaign = true"
                    >
                    </EmptyStateContainer>
                </template>

                <template v-slot:item.days_overrides="{ item }">
                    {{item.days_overrides.map(d => {
                        let day = CALENDAR.DAYS_LIST.find(c => c.value === d);
                        return day.name
                    }).join(', ') }}
                </template>

                <template v-slot:item.months_overrides="{ item }">
                    {{item.months_overrides.map(d => {
                        let month = CALENDAR.MONTHS_LIST.find(c => c.value === d);
                        return month.name
                    }).join(', ') }}
                </template>

                <template v-slot:item.value_tiers="{ item }">
                    {{item.value_tiers.map(t => $options.filters.capitalize(t)).join(', ') }}
                </template>

                 <template v-slot:item.rent_management_plan_id="{ item }">
                    {{item.RentManagementPlan?.name }}
                </template>
                <template v-slot:item.created_by="{ item }">
                    {{item.CreatedBy?.name }}
                </template>

                <template v-slot:item.conditions="{ item }">
                    {{item.conditions.map(c => conditions[c]).join(', ') }}
                </template> 

                 <template v-slot:item.actions="{ item }">

                     <v-menu close-on-click  close-on-content-click offset-y class="d-inline-block" >
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on" class="d-inline-block">
                                <hb-btn icon active-state-off>
                                    mdi-dots-vertical
                                </hb-btn>
                            </span>
                        </template>
                        <v-list>
                            <v-list-item :ripple="false"  @click="manageRateCampaign(item)">
                                <v-list-item-title >Manage Rate Campaign</v-list-item-title>
                            </v-list-item>
                            <v-list-item :ripple="false"  @click="deleteRateCampaign(item)">
                            <v-list-item-title>Delete Rate Campaign</v-list-item-title>
                            </v-list-item>
                        </v-list>
                        </v-menu>
                </template>


            </hb-data-table>

         </template>

        <ManageRateCampaign @saved="onSaved" :selected="selected" @close="showManageRateCampaign = false" v-if="showManageRateCampaign" />

        <hb-modal
            size="medium"
            title="Delete Rate Campaign"
            v-model="showDeleteRateCampaign"
            confirmation
        >
            <template v-slot:content>
                <div class="py-4 px-6">
                    Are you sure you want to delete <strong>{{selected.name}}</strong>?
                    <br /><br />
                    This action cannot be undone.
                </div>
            </template>
            <template v-slot:left-actions>
            </template>
            <template v-slot:right-actions>
                <hb-btn color="destructive" @click="confirmDeleteRateCampaign">Delete Rate Campagin</hb-btn>
            </template>
        </hb-modal>



    </div>
</template>
<script>
    import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";
    import ManageRateCampaign from "./ManageRateCampaign.vue";
    import api from "../../../assets/api.js";
    import CALENDAR from '@/constants/calendar.js';
    import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
    import { mapMutations, mapState, mapActions, mapGetters } from "vuex"
    import { notificationMixin } from "@/mixins/notificationMixin.js";
    export default {
        name: "RateCampaigns",
        data() {
            return {
                CALENDAR: {},

                notification: true,
                initialDataLoading: false,
                how_to_trigger: true,
                expand_direction: false,
                conditions: {
                    rent_now: 'Rent Now Only'
                }, 
                showDeleteRateCampaign: false,
                expansion_panel: false,
                search: '',
                loading: false,
                showManageRateCampaign: false,
                items: [],
                selected: {
                    name: ''
                }, 
                headers: [
                    {
                        text: 'Name & Description',
                        sortable: true,
                        value: 'name',
                    },
                    {
                        text: 'Rate',
                        sortable: true,
                        value: 'rate',
                    },
                    {
                        text: 'Occupancy Threshhold',
                        sortable: true,
                        value: 'occupancy_threshold',
                    },
                    {
                        text: 'Days',
                        sortable: true,
                        value: 'days_overrides',
                    },
                    {
                        text: 'Month',
                        sortable: true,
                        value: 'months_overrides',
                    },
                    {
                        text: 'Value Tiers',
                        sortable: true,
                        value: 'value_tiers',
                    },
                    {
                        text: 'Rent Plan',
                        sortable: true,
                        value: 'rent_management_plan_id',
                    },
                    {
                        text: 'Created by',
                        sortable: true,
                        value: 'created_by',
                    },
                    {
                        text: 'Conditions',
                        sortable: true,
                        value: 'conditions',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                    },
                ],
            };
        },
        mixins: [notificationMixin],
        components: {
            EmptyStateContainer,
            ManageRateCampaign,
            ConfirmationPopup
        },
        filters:{
             capitalize(value){
                if (!value) return '';
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            }
        }, 
        computed:{
            ...mapGetters({
                // defaultRatePlan: "revManStore/defaultRatePlan",
                // companyRatePlanConf: "revManStore/companyRatePlanConf",
                hasRolePermission: "authenticationStore/rolePermission",
                property: "revManStore/getSelectedProperty",
                // hasInternalRateEngine: "revManStore/hasInternalRateEngine",
                // rateEngine: "revManStore/rateEngine",
            }),
            hasNoRateCampaigns(){
                return !this.items.length && !this.initialDataLoading;
            }
        },
        created(){
            this.CALENDAR = CALENDAR;
            this.getRateCampaigns(true);

        },
        methods: {
            async onSaved(){
                await this.getRateCampaigns();
                this.closeWindow();
            },
            closeWindow(){
                this.selected = {};
                this.showDeleteRateCampaign = false;
                this.showManageRateCampaign = false;
            },
            manageRateCampaign(campaign){
                this.selected = campaign,
                this.showManageRateCampaign = true;
            }, 
            deleteRateCampaign(campaign){
                this.selected = campaign,
                this.showDeleteRateCampaign = true;
            }, 
            async confirmDeleteRateCampaign(){
                 try {
                    let response = await api.delete(this, api.COMPANY_RATE_CAMPAIGN + `${this.selected.id}`);
                    
                    this.showMessageNotification({
                        type: "success", 
                        description: `${this.selected.name} has been deleted`,
                    })
                    await this.getRateCampaigns();
                    this.closeWindow();
                } catch (err) {
                    console.error("Rate Campaign fetch error", err);
                    this.showMessageNotification({
                        description: err,
                    })
                }

            }, 
            async getRateCampaigns(initial){
                if(initial) this.initialDataLoading = true;
                 try {
                    let response = await api.get(this, api.COMPANY_RATE_CAMPAIGN + (this.search ? `?search=${this.search}`: '') );
                    this.items = response?.rate_campaigns;
                    
                    // EventBus.$emit("rateManagementByPropertyEvent", { event: 'refreshTable' })
                } catch (err) {
                    console.error("Rate Campaign fetch error", err);
                    this.showMessageNotification({
                        description: err,
                    })
                }
                this.initialDataLoading = false;
                
            }
        },
        watch: {
            search: _.debounce(async function() {
                this.getRateCampaigns()
            }, 300)
        }
    
    }
</script>