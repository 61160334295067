<template>
  <div>
    <div ref="rmTopSection">
      <hb-header full>
        <hb-page-header
          title="Rate Management Center"
          description="Manage your Sell Rate strategy by creating Rate Plans and assigning them to your Categories. Analyse the impact of your strategy using Variance, Potential Income and other tools."
        >
        </hb-page-header>
      </hb-header>
    </div>
    <div v-if="showLocalPropertySelector" ref="rmPropertySection" class="d-flex align-center mr-n5">
      <HbPropertySelector
        v-model="property"
        :items="properties"
        item-value="id"
        item-text="name"
      />
    </div>
    <!-- MAIN SECTION STARTS -->
    <div>
      <!-- TABS -->
      <div ref="rmTabSection">
        <hb-tabs v-model="activeTab">
          <v-tab v-for="item in tabOptions" :key="item.key">
            {{ item.label }}
            <hb-tooltip bottom>
              <template v-slot:item>
                <hb-icon class="ml-2" small>mdi-information</hb-icon>
              </template>
              <template v-slot:body>
                <span v-html="item.description"/>
              </template>
            </hb-tooltip>
          </v-tab>
        </hb-tabs>
        <v-divider></v-divider>
      </div>
      <!-- TAB ITEMS -->
      <v-tabs-items class="rm-tab-items" v-model="activeTab">
        <v-tab-item class="tab-item">
          <RateManagementByProperty
            :has-property-selector="showLocalPropertySelector"
            :previous-space-group-hash="previousSpaceGroupHash"
          />
        </v-tab-item>
        <v-tab-item class="tab-item">
          
          <Libraries />
          
        </v-tab-item>
        <v-tab-item class="tab-item">
          <ManageValueBasedPricing />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>

import RateManagementByProperty from "./RateManagementByProperty.vue";
import Libraries from "./Libraries.vue";
import ManageValueBasedPricing from "./ManageValueBasedPricing.vue";
import PropertySelector from "./PropertySelector.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "RateManagementHome",
  data() {
    return {
      tabOptions: [
        { label: "Rate Management by Property", key: 1, description: "Assign and manage rates and promotions for each space group at your property.<br> Please visit Space Groups under Hummingbird Settings to create a new space group." },
        { label: "Libraries", key: 2, description: "Create and manage rate plans on a corporate level. <br>The changes you make to one plan will affect all properties in the corporate level. <br>The default plan would be assigned to all space groups in the corporate level. <br>Changing the default plan would change the plans across all properties." },
        // { label: "View and Manage Rate Plans", key: 2, description: "Create and manage rate plans on a corporate level. <br>The changes you make to one plan will affect all properties in the corporate level. <br>The default plan would be assigned to all space groups in the corporate level. <br>Changing the default plan would change the plans across all properties." },
        { label: "Manage Value Pricing", key: 3, description: "Choose the pricing system that will be used to manage your property's rates.<br> Add and manage verbiage and feature sorting for the Mariposa website value pricing tiers." },
      ],
      activeTab: null,
      property: null,
      previousSpaceGroupHash: "",
    };
  },
  props: {
    previousData: {
      type: Object,
      default: () => {},
    },
  },
  components: {

    PropertySelector,
    RateManagementByProperty,
    ManageValueBasedPricing,
    Libraries
  },
  async mounted() {
    await this.fetchRatePlans();
    if (!this.showLocalPropertySelector) this.fetchPropertySettings();
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      spaceData: "revManStore/getSpaceData",
      selectedProperty: "revManStore/getSelectedProperty",
    }),
    hasSpaceData() {
      return Object.keys(this.spaceData).length;
    },
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
  },
  watch: {
    property: async function (value) {
      await this.setProperty(value);
      await this.fetchPropertySettings();
    },
    previousData: {
      handler(prev, next) {
        // Let it run on background
        this.setPreviousData(this.previousData);
      },
      immediate: true,
    },
  },
  async destroyed() {
    if(!this.$route.fullPath.includes('/rate-management'))
    await this.setProperty(null);
  },
  methods: {
    ...mapActions({
      fetchRatePlans: "revManStore/fetchRatePlans",
      setProperty: "revManStore/setProperty",
      fetchPropertySettings:
        "revManStore/fetchCurrentPropertyRatePlanConfiguration",
    }),
    setPreviousData(data) {
      if (data?.propertyId) {
        this.property = data.propertyId;
        this.previousSpaceGroupHash = data?.hash;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rm-tab-items {
  background-color: #f9fafb;
}
</style>