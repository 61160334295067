<template>
  <PlanCardLayout :tabs="tabsData" :apiLoading="apiLoading" :isSelected="isSelected">
    <template slot="title-icons">
      <template v-if="showDescriptiveIcons">
        <DescriptiveTags :plan=descriptiveTagProperties :conf="{ class: 'mr-2' }" />
      </template>
    </template>
    <template slot="title">
      {{ rate.name }}
    </template>
    <template v-if="isDefault" slot="default-icon">
      <hb-icon color="#00848E">
        mdi-flag
      </hb-icon>
    </template>
    <template v-if="isManageable" slot="option">
      <hb-menu options>
        <v-list>
          <v-list-item
            :ripple="false"
            @click="showRatePlanForm = !showRatePlanForm"
          >
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="!isDefault"
            :ripple="false"
            @click="showConfirmationPopup()"
          >
            <v-list-item-title>Set as Default</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="!isDefault"
            :ripple="false"
            @click="showConfirmationPopup('delete')"
          >
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </hb-menu>
    </template>
    <template v-else-if="isClosable" slot="option">
      <hb-btn @click="$emit('close')" active-state-off icon tooltip="Close">
        mdi-close
      </hb-btn>
    </template>
    <template slot="description">
      {{ rate.description }}
    </template>
    <template v-for="tab in tabsData">
      <template :slot="tab.key">
        <component
          :is="tab.componentName"
          v-bind="tab.componentProps"
          :key="tab.key"
        />
      </template>
    </template>
    <template slot="form">
      <!-- Form Modal for Edit rate plan -->
      <RatePlanForm
        v-model="showRatePlanForm"
        :rate="rate"
        isEdit
        :rate_campaigns="rate_campaigns"
        @submit="upateRatePlan"
      />
    </template>
    <template slot="confirmation">
      <!-- Confirmation Modal for delete rate plan and set as default -->
      <ConfirmationPopup v-once ref="confirmationPopup" />
    </template>
  </PlanCardLayout>
</template>
<script>
import RatePlanList from "./RatePlanList.vue";
import RatePlanForm from "./RatePlanForm.vue";
import PlanCardLayout from "../utils/PlanCardLayout.vue";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";
import DescriptiveTags from "../utils/DescriptiveTags.vue";
import PlanSettings from "./PlanSettings.vue";
import RateCampaignSettings from "./RateCampaignSettings.vue";
import { mapState } from "vuex";
import api from "../../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "RateCard",
  mixins: [notificationMixin],
  props: {
    rate: {
      type: Object,
      required: true,
      default: () => {},
    },
    type: {
      type: String,
      default:'manage'
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    editPermission: {
      type: Boolean,
      default: true
    },
    showDescriptiveIcons: {
      type: Boolean,
      default: true
    },
    rate_campaigns: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapState({
      company: (state) => state.authenticationStore.company,
    }),
    confirmationContent() {
      return {
        delete: {
          title: "Delete Rate Plan",
          description: `You are about to delete <strong>${this.rate.name}</strong> rate plan.<br/><br/>Are you sure you want to continue?`,
        },
        default: {
          title: "Set Default Rate Plan",
          description: " Be advised that if you select a new default rate plan, then any groups currently using your default plan will change over to your new selection."
          // description: `You are about to set <setrong>${this.rate.name}</strong> as the new default rate plan for <strong>${this.company.name}</strong>. This would change <strong>ALL</strong> the space groups set to the current plan to the new one, <strong>across all properties</strong> using Hummingbird’s rate management. <br/><br/>Are you sure you want to proceed?`,
        },
      };
    },
    isManageable() {
      return this.type == 'manage' && this.editPermission
    },
    isClosable() {
      return this.type == 'close'
    },
    showDefaultIconOnRight() {
      return this.type == 'select'
    },
    isDefault() {
      return this.rate?.isDefault ?? false
    },
    descriptiveTagProperties() {
      return {
        tags: this.rate?.tags ?? [],
        price_delta_type: this.rate?.price_delta_type ?? ''
      }
    },
    tabsData() { //TODO

      let tabs = [
        {
          label: "Rate Plan",
          key: "rate-plan",
          componentName: "RatePlanList",
          componentProps: { rate: this.rate },
        },
        {
          label: "Plan Settings",
          key: "plan-settings",
          componentName: "PlanSettings",
          componentProps: { rate: this.rate },
        }
      ];
      
      let rc_data = this.rate_campaigns.find(r => r.id == this.rate.rate_campaign_id )
      if(rc_data){
        tabs.push({
          label: "Rate Campaign",
          key: "rate-campaign",
          componentName: "RateCampaignSettings",
          componentProps: { rate_campaign: rc_data },
        })
      }
      return tabs
    }
  },
  components: { RatePlanList, PlanSettings, RatePlanForm, PlanCardLayout, ConfirmationPopup, DescriptiveTags, RateCampaignSettings },
  data() {
    return {
      // tabs: [
      //   {
      //     label: "Rate Plan",
      //     key: "rate-plan",
      //     componentName: "RatePlanList",
      //     componentProps: { rate: this.rate },
      //   },
      //   {
      //     label: "Plan Settings",
      //     key: "plan-settings",
      //     componentName: "PlanSettings",
      //     componentProps: { rate: this.rate },
      //   },
      // ],
      activeTab: null,
      showRatePlanForm: false,
      apiLoading: false,
      confirmationType: "delete",
      loading: false,
    };
  },
  methods: {
    /**
     * Function to display the confirmation modal.
     *
     * @param {String} type Type of the confirmation modal ( default/delete)
     */
    async showConfirmationPopup(type = "default") {
      this.confirmationType = type;
      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: this.confirmationContent[type].title,
          message: this.confirmationContent[type].description,
          buttonType: type == 'delete' ? 'destructive' : 'primary',
          resolver: `Continue`,
        })
        .catch(() => false);
        if (confirmed) this.triggerPostConfirmationAction()
    },

    /**
     * Function to trigger all the curresponding action after user confirms the delete or set as default actions
     *
     * If confirmation type is default, it triggers the API for setting the rate plan as company default
     * If confirmation type is delete, it triggers the API for deleting the rate plan.
     */
    triggerPostConfirmationAction() {
      this.loading = true;
      if (this.confirmationType == "default")
        this.$emit("setDefault", {
          callback: () => {
            this.loading = false;
          },
          id: this.rate.id,
        });
      else this.deleteRatePlan();
    },

    /**
     * Function to update rate plan
     *  - triggers the API for updating the rate plan
     *  - fetches the rate plan list after the update
     */
    async upateRatePlan(value) {
      this.apiLoading = true;
      try {
        let response = await api.put(
          this,
          api.COMPANY_RATE_MANAGEMENT + "plans/" + this.rate.id,
          value
        );

        this.showMessageNotification({
          type: "success",
          description: `${this.rate.name} Plan has been updated.`,
        });
        await this.$emit("fetchData");
      } catch (err) {
        console.error(err);
        this.showMessageNotification({
          description: `Unable to update Rate Plan.`,
        });
      }
      this.apiLoading = false;
    },

    /**
     * Function to delete rate plan
     *  - triggers the API for deleting the rate plan
     *  - fetches the rate plan list after the deletion
     */
    async deleteRatePlan() {
      this.loading = true;
      try {
        await api.delete(
          this,
          api.COMPANY_RATE_MANAGEMENT + "plans/" + this.rate.id
        );
        await this.$emit("fetchData");
        this.showMessageNotification({
          type: "success",
          description: `${this.rate.name} Plan has been deleted.`,
        });
      } catch (err) {
        console.error(err);
        this.showMessageNotification({
          description: `Unable to delete Rate Plan.`,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>