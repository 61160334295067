<template>
  <v-dialog
    v-model="dialog"
    width="1366"
    scrollable
    slot="body"
    :content-class="'hb-modal-wrapper'"
  >
    <v-card>
      <v-card-title class="hb-modal-title d-flex align-center ma-0 pa-0">
        <v-col :cols="10" class="ma-0 py-0 px-4 d-flex align-center">
          <span class="hb-modal-title-font-size">Assign Rate Plan</span>
        </v-col>
        <v-col
          :cols="2"
          class="
            ma-0
            pa-0
            hb-modal-header-close-padding-right
            d-flex
            justify-end
          "
        >
          <hb-btn icon tooltip="Close" @click="_cancel()" active-state-off>
            mdi-close
          </hb-btn>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="pa-0 ma-0"
        style="height: 700px"
        id="assign_rateplan_modal_content"
      >
        <div>
          <span>
            <div class="hb-modal-sub-header px-4 py-4">
              Select the profile you want to assign to this group from the
              drop-down menu and choose whether or not you want the profile to
              be active.
            </div>
            <div class="px-4 pb-4" v-if="isRateplanChanged">
              <hb-notification
                v-model="isRateplanChanged"
                title="Note:"
                not-dismissable
                type="caution"
              >Any changes to the Rate Plans will take effect after midnight only.
              </hb-notification>
            </div>
            <v-divider></v-divider>
          </span>
          <span class="hb-modal-content">
            <div class="size-detail px-4 py-2">
              <v-row>
                <v-col cols="12" class="pb-0 d-flex align-center">
                  <hb-unit-icon
                    :key="config.space_category"
                    :unit="{ unit_type: config.space_category }"
                    small
                    color="#101318"
                    class="mr-2"
                  />
                  <!-- <hb-icon color="#101318" class="mr-3">
                    <template v-if="config.space_category == 'storage'">mdi-facility-custom-3</template>
                    <template v-else>mdi-rv-truck</template>
                  </hb-icon> -->
                  <strong class="mr-2 text-capitalize"
                    >
                    {{ config.space_category }}: 
                    {{ config.spacegroup_breakdown }}<span v-if="config.spacegroup_breakdown && config.size">: </span>
                    {{ config.size }}
                  </strong>
                </v-col>
              </v-row>
              <v-row class="pt-3">
                <v-col
                  cols="4"
                  v-for="(item, i) in config.info"
                  :key="i"
                  class="
                    plan-row
                    d-flex
                    justify-start
                    align-center
                    py-1
                    pe-4
                  "
                >
                  <span class="plan-label"
                    ><strong class="pe-0">{{ item.label }}: </strong> {{ item.value }}</span
                  >
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>
            <div class="rate-card-wrap pa-4">
              <!-- Display the details of each rate plan -->
              <RateCard
                v-for="(rate, index) in config.profiles"
                :key="index"
                :rate="rate"
                :type="'select'"
                :isSelected="rate.id == config.selected_profile"
                @click.native="selectRate(rate.id)"
                class="rate-selection"
              />
            </div>
          </span>
        </div>
      </v-card-text>
      <hb-bottom-action-bar @close="_cancel()">
        <template v-slot:left-actions>
          <help-juice />
        </template>
        <template v-slot:right-actions>
          <slot name="right-actions"></slot><hb-btn @click="_save">Save</hb-btn>
        </template>
      </hb-bottom-action-bar>
    </v-card>
  </v-dialog>
</template>

<script>
import RateCard from "./RateCard.vue";

import { cloneDeep } from "lodash";
export default {
  name: "AssignRatePlan",
  data() {
    return {
      dialog: false,
      config: {
        size: "",
        space_category: "",
        active: false,
        info: [],
        profiles: [],
        selected_profile: "",
      },
      initialConfig: null,
      resolvePromise: null,
      rejectPromise: null,
    };
  },
  components: { RateCard },
  watch: {
    dialog(value) {
      if (value)
        this.$nextTick(() => {
          let element = document.getElementById(
            "assign_rateplan_modal_content"
          );
          element.scrollTo({
            top: 0,
          });
        });
    },
  },
  computed: {
    isRateplanChanged() {
      return (
        this.initialConfig?.selected_profile !== this.config?.selected_profile
      );
    },
  },
  methods: {
    show(ratePlanConfig) {
      this.config = Object.assign({}, this.config, ratePlanConfig);
      this.initialConfig = cloneDeep(this.config);
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    _save() {
      this.dialog = false;
      let { active, selected_profile } = this.config;
      this.resolvePromise({ active: !!active, selected_profile });
    },
    _cancel() {
      this.dialog = false;
      this.rejectPromise(new Error("Operation cancelled by user"));
    },
    selectRate(index) {
      this.config.selected_profile = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.size-detail {
  background-color: #f4f6f8;
  .space-category {
    text-transform: capitalize;
  }
}
.hb-modal-sub-header {
  min-height: 44px;
  // padding-top: 11px;
  // padding-bottom: 14px;
  font-size: 14px;
  color: #637381;
}

.hb-modal-content {
  font-size: 14px;
  color: #101318;
}

.hb-modal-header-close-padding-right {
  padding-right: 8px !important;
}

.hb-modal-content .v-input .v-label {
  line-height: 20px;
}

.hb-modal-title-font-size {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.hb-modal-title {
  min-height: 52px;
}

.v-card__actions.hb-modal-footer-wrapper {
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  height: 60px;
  padding-left: 8px;
}
.rate-card-wrap {
  display: grid;
  height: 500px;
  overflow: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;
  @media (max-width: 1970px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1470px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}
.loader-card {
  width: 100%;
}
</style>