<template>
  <hb-modal show-help-link :title="title" v-model="dialog">
    <template v-slot:subheader>
      Fully customize your rate plan. Ensure the name of the rate plan clearly describes the scenario you’re creating a rate plan for (i.e. High Occupany Climate Control, Low Occupancy Drive-Up) and then fill out the rest of the fields to your desired specifications to save and complete your new rate plan. 
    </template>
    <template v-slot:content>
      <div>
        <hb-form label="Name" required>
          <HbTextField
            v-model="form.name"
            v-validate="'required'"
            data-vv-name="name"
            data-vv-as="Name"
            :error="errors.collect('name').length > 0"
            placeholder="Enter Name"
          />
        </hb-form>
        <hb-form label="Description" required>
          <HbTextarea
            v-model="form.description"
            v-validate="'required|max:255'"
            data-vv-name="description"
            data-vv-as="Description"
            :maxlength="255"
            :error="errors.collect('description').length > 0"
            placeholder="Enter Description"
            placeholder-fix
          />
        </hb-form>
        <hb-form label="Change by" required>
          <HbSelect
            v-model="form.price_delta_type"
            :items="deltaTypes"
            item-text="label"
            item-value="value"
            v-validate="'required|max:45'"
            data-vv-name="method"
            data-vv-as="Method"
            :error="errors.collect('method').length > 0"
            placeholder="Select"
          />
        </hb-form>
        <DescriptiveTagForm v-model="form.tags" />
        <hb-form label="Rate Plan" required full v-if="dialog">
          <RateSetting
            ref="rateSetting"
            v-for="(item, idx) in form.settings"
            :key="idx"
            :index="idx + 1"
            :remove="idx != 0"
            v-model="form.settings[idx]"
            :method="form.price_delta_type"
            @remove="removeEntry"
          />
          <div class="mt-4">
            <hb-link @click="addNewEntry()">+ Add Threshold</hb-link>
          </div>
        </hb-form>
        <RoundingForm v-model="form.round_to" />
        
        <!-- <hb-form label="Rate Campaign" required>
          <HbSelect
            v-model="form.rate_campaign_id"
            :items="rate_campaigns"
            item-text="name"
            item-value="id"
            data-vv-name="rate_campaign"
            data-vv-as="RateCampagin"
            :error="errors.collect('rate_campaign').length > 0"
            placeholder="Select"
          />
        </hb-form> -->


      </div>
      <ConfirmationPopup v-if="hasPlanId" v-once ref="confirmationPopup" />
    </template>
    <template v-slot:right-actions>
      <hb-btn @click="showConfirmationPopup">Save</hb-btn>
    </template>
  </hb-modal>
</template>

<script>
import RoundingForm from "../../assets/RoundingForm.vue";
import DescriptiveTagForm from "../utils/DescriptiveTagForm.vue";
import RateSetting from "./RateSetting.vue";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { cloneDeep } from "lodash";
import ConfirmationPopup from "../utils/ConfirmationPopup.vue";


export default {
  name: "RatePlanForm",
  mixins: [notificationMixin],
  props: {
    rate: {
      type: Object,
      default: () => undefined,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
    },
    rate_campaigns: {
      type: Array,
      default: () => [],
    }
  },
  components: { RoundingForm, RateSetting, DescriptiveTagForm, ConfirmationPopup },
  watch: {
    /**
     * Fuction for resetting and updating component data based on modal toggle.
     */
    dialog() {
      this.resetDialog();
    },
  },
  data() {
    return {
      form: {},
      notificationType: "success",
      deltaTypes: [
        {
          label: "Dollar",
          value: "dollar",
        },
        {
          label: "Percentage",
          value: "percentage",
        },
      ],
      errorsList: [],
      occupancyPercentageList: [],
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    /**
     * @returns {String} Modal title for Edit and Create rate plan
     */
    title() {
      return this.hasPlanId ? "Edit Rate Plan" : "Add Rate Plan";
    },
    hasPlanId() {
      return Boolean(this.rate?.id )
    }
  },
  created(){
    this.resetDialog();
  },
  methods: {
    resetDialog(){
      this.$validator.reset();
      this.$validator.errors.clear();
      if (this.isEdit) this.setInitialData();
      else this.resetForm();
    },
    getRateCampaigns(){
 
    }, 
    resetForm() {
      this.form = {
        name: "",
        description: "",
        price_delta_type: "dollar",
        round_to: null,
        settings: [
          {
            occupancy_percentage: 0,
            type: null,
            value: 0,
          },
        ],
        tags: []
      };
    },

    /**
     * Function to display the confirmation modal.
     *
     * @param {String} type Type of the confirmation modal ( default/delete)
     */
    async showConfirmationPopup() {
      this.$validator.errors.clear();
      const valid = await this.$validator.validateAll();

      //validate the fields inside the RateSettings component.
      await this.validateSettings();

      if (!valid || this.errors.items.length) {
        return;
      }
      if (this.hasPlanId) {
        let confirmed = await this.$refs.confirmationPopup
          .show({
            title: `Edit Rate Plan`,
            message: `You are about to edit <strong>${this.rate?.name}</strong> rate plan. Editing this would apply the changes to all existing space groups that has this plan.<br/><br/>Are you sure you want to continue?`,
            buttonType: 'primary',
            resolver: `Continue`,
          })
          .catch(() => false);
          if (confirmed) this.submit()
      } else this.submit()
    },

    /**
     * Function to fill the form object with data from rate props
     */
    setInitialData() {
      this.form = {
        name: this.rate?.name,
        rate_campaign_id: this.rate?.rate_campaign_id,
        description: this.rate?.description,
        price_delta_type: this.rate?.price_delta_type,
        round_to: this.rate?.round_to,
        settings: cloneDeep(this.rate?.settings),
        tags: this.rate?.tags ?? []
      };
    },

    /**
     * Function to add new threshold (settings row)
     */
    addNewEntry() {
      let rateSetting = {
        occupancy_percentage: 0,
        type: null,
        value: 0,
      };
      this.form.settings.push(rateSetting);
    },

    /**
     * Function to remove a rate settings row
     */
    removeEntry(index) {
      this.form.settings.splice(index - 1, 1);
    },

    /**
     * Function to submit form data after validation checks.
     *
     * The settings are sorted in decending order before submittion.
     *
     */
    async submit() {
      //sort settings in descending order of occupancy
      this.form.settings.sort((a, b) => {
        return b.occupancy_percentage - a.occupancy_percentage;
      });

      this.$emit("submit", this.nullifyEmptyArrays(this.form));
      this.dialog = false;
    },
    /**
     * Function to replace empty arrays with NULL on objects
     *
     * @param {*} obj target object
     */
    nullifyEmptyArrays(obj) {
      if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
        return Object.entries(obj).reduce((acc, [key, val]) => {
          if (Array.isArray(val) && !val.length) acc[key] = null
          else acc[key] = val
          return acc
        }, {})
      } else return obj
    },
    /**
     * Function to check for errors in occupancy by field
     * Conditions:
     *  - Value is required
     *  - Value must be a natural number less than 100
     *  - Value shouldn't be repeated
     *
     * @param {String} value occupancy field value entered by user
     *
     * @returns {Boolean} true or false based on above conditions
     */
    hasOccupancyError(value) {
      if (value || value === 0) {
        //Value must be a natural number less than 100
        if (/^[0-9][0-9]?$/.test(value)) {
          let occupancy = parseInt(value);
          //Occupancy cannot be repeated
          if (!this.occupancyPercentageList.includes(occupancy)) {
            this.occupancyPercentageList.push(occupancy);
            return false;
          }
        }
      }
      return true;
    },

    /**
     * Function to check for errors in rate by field
     * Conditions:
     *  - Value is required
     *  - Value shouldnt have more than two decimal places. (only in case of dollar method)
     *  - In case of percentage no decimal places will be allowed
     *
     * @param {String} value rate-by field value entered by user
     * @param {String} changeBy by which method price should be altered
     * @returns {Boolean} true or false based on above conditions
     */
    hasRateByError(value, changeBy) {
      let regex = {
        percentage:/^[0-9]+([0-9])?$/,
        dollar: /^\d+(\.\d{0,2})?$/
      }[changeBy || 'dollar']

      if (!isNaN(+value) && regex.test(value)) {
        return false;
      }

      return true;
    },

    /**
     * Function to manualy validate the fields inside the RateSettings component.
     * And push curresponding message to vee validate error list.
     *
     * Only one error message will be pushed for each field type.
     *
     */
     async validateSettings() {
      this.occupancyPercentageList = []
      let errorMessages = []

      const promises = this.$refs.rateSetting.map(async (setting) => {
          let ratePlan = setting.ratePlan
          let changeBy = this.form.price_delta_type

          //Occupancy validation
          let occupancyError = this.hasOccupancyError(ratePlan.occupancy_percentage)

          if (occupancyError)
              errorMessages.push('Occupancy field is required with a value ranging between 0-99 and not repeated. Decimal values are not allowed')

          //Type validation
          if (!ratePlan.type) errorMessages.push('Rate plan type field is required.')

          //Rate by field valiation
          let rateByError = this.hasRateByError(ratePlan.value, changeBy)
          if (rateByError)
              errorMessages.push(
                changeBy === 'dollar'
                  ? 'Rate By field is required with a value with the maximum of two decimal places.'
                  : 'Rate by field is required and should be a whole number'
              )

          //set corresponding field error to highlight the field with red color.
          setting.showError({
              occupancy: occupancyError,
              type: !ratePlan.type,
              value: rateByError
          })

          //Triggers v-validate in fields to highlight fields with errors
          await setting.$validator.validateAll()
      })

      errorMessages = [...new Set(errorMessages)]

      //Display error message for each field type
      errorMessages.forEach((msg) => {
          this.$validator.errors.add({
              field: 'settings',
              msg
          })
      })

      await Promise.all(promises)
    }
  },
};
</script>
