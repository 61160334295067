<template>
  <div class="rate-plan-list pa-6">
    <div
      class="
        d-flex
        align-center
        justify-space-between
        rate-plan-list-header
        pb-2
        pr-1
      "
    >
      <span>Occupancy Threshold</span>
      <span>Rate Change</span>
    </div>
    <v-divider></v-divider>
    <div class="rate-plan-content">
      <div v-for="(item, idx) in ratePlansWithRange" :key="idx">
        <div class="d-flex align-center justify-space-between py-2 pr-1">
          <span>{{ item.occupancy_percentage }}</span>
          <span :class="item.type">
            {{ getRateChange(item) }}
          </span>
        </div>
        <v-divider></v-divider>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
export default {
  name: "RatePlanList",
  props: {
    rate: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    /**
     * Function to convert occupancy of each settings into a range
     *
     *  - The occupancy value will be updated to a string in the "<starting-occupancy>% - <ending-occupancy>%" form.
     *  - The 'starting occupancy' will be same as the occupancy value for all the settings
     *  - For the first setting, 100 will be taken as the 'ending occupancy'
     *  - For the subsequent settings, the occupancy value of the previous one will be taken as the 'ending occupancy'
     *
     * @returns {Array} list of setting with range converted occupancy value
     */
    ratePlansWithRange() {
      const rules = cloneDeep(this.rate.settings);
      const noOfRules = (rules ?? []).length;
      const ratePlansWithRange = [];
      for (let i = 0; i < noOfRules; i++) {
        const currRule = rules[i];
        let currOccupancy = currRule.occupancy_percentage;
        const prevOccupancy =
          i == 0 ? "100" : this.rate.settings[i - 1]?.occupancy_percentage;
        currRule.occupancy_percentage = `${currOccupancy}% - ${prevOccupancy}%`;
        ratePlansWithRange.push(currRule);
      }
      return ratePlansWithRange;
    },
  },
  methods: {
    /**
     * Function to update the rate change value based on setting type and price delta type
     *
     * Prepend the value with a -(minus) symbol if the setting type is 'decrease'
     * Append $(dollar) or Prepend %(percentage) symbol based on price_delta_type value
     *
     * @returns {String} updated rate change value
     */
    getRateChange(item) {
      let rateChange =
        this.rate.price_delta_type == "dollar"
          ? `$${item.value}`
          : `${item.value}%`;
      return item.type != "increase" ? `-${rateChange}` : rateChange;
    },
  },
  data() {
    return {
      tabs: [
        { label: "Rate Plan", key: "rate-plan" },
        { label: "Plan Settings", key: "plan-settings" },
      ],
      activeTab: null,
    };
  },
};
</script>
<style lang="scss" scoped>
.rate-plan-list {
  .rate-plan-list-header {
    color: #637381;
    font-size: 14px;
    font-weight: 400px;
  }
  .rate-plan-content {
    max-height: 305px;
    overflow: auto;
    .increase {
      color: #02ad0f;
    }
    .decrease {
      color: #fb4c4c;
    }
  }
}
</style>