<template>
  <v-dialog
    v-model="dialog"
    width="1000"
    scrollable
    slot="body"
    :content-class="'hb-modal-wrapper'"
  >
    <v-card>
      <v-card-title class="hb-modal-title d-flex align-center ma-0 pa-0">
        <v-col :cols="10" class="ma-0 py-0 px-4 d-flex align-center">
          <span class="hb-modal-title-font-size">Assign Promotions</span>
        </v-col>
        <v-col
          :cols="2"
          class="
            ma-0
            pa-0
            hb-modal-header-close-padding-right
            d-flex
            justify-end
          "
        >
          <hb-btn icon tooltip="Close" @click="_cancel()" active-state-off>
            mdi-close
          </hb-btn>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="pa-0 ma-0"
        style="height: 418px"
        id="manage_promos_section"
      >
      <!-- style="height: 455px" --> 
        <div>
          <span>
            <div class="hb-modal-sub-header px-4 py-4">
              Select the promotions and the discounts you want to assign to this
              Space Group from the drop-down menu.
            </div>
            <v-divider></v-divider>
          </span>
          <span class="hb-modal-content">
            <div class="size-detail px-4 py-2">
              <v-row>
                <v-col cols="12" class="pb-0 d-flex align-center">
                  <hb-unit-icon
                    :key="config.space_category"
                    :unit="{ unit_type: config.space_category }"
                    small
                    color="#101318"
                    class="mr-2"
                  />
                  <strong class="mr-2 text-capitalize">
                    {{ config.space_category }}: 
                    {{ config.spacegroup_breakdown }}<span v-if="config.spacegroup_breakdown && config.size">: </span>
                    {{ config.size }}
                  </strong>
                </v-col>
              </v-row>
              <v-row class="pt-3">
                <v-col
                  cols="4"
                  v-for="(item, i) in config.info"
                  :key="i"
                  class="
                    plan-row
                    d-flex
                    justify-space-between
                    align-center
                    py-1
                    pe-4
                  "
                >
                <span class="plan-label"
                >
                  <strong class="pe-0">{{ item.label }}: </strong> {{ item.value }}
                </span>
                </v-col>
              </v-row>
            </div>
            <div class="pa-4">
              <v-divider />
              <span v-if="initialLoading">
                <v-skeleton-loader v-for="n in 4" :key="n" type="list-item" /> <!--default will be 5-->
              </span>
              <div v-else>
                <span
                  v-for="(promoKey, position) in Object.keys(promoInputs)"
                  :key="position"
                >
                  <hb-form
                    :label="promoInputs[promoKey].label"
                    large
                    class="styled-form"
                  >
                    <hb-select
                      v-model="selectedPromos[promoKey]"
                      :items="
                        promoInputs[promoKey].type == 'discount'
                          ? filteredDiscounts
                          : defaultPromotions
                      "
                      item-value="id"
                      item-text="name"
                      data-vv-scope="default"
                      :data-vv-name="promoInputs[promoKey]"
                      :data-vv-as="promoInputs[promoKey]"
                      :placeholder="`No ${promoInputs[promoKey].label} Selected`"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>
                            <span class="hb-font-body-medium hb-text-night">{{
                              data.item.name
                            }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="hb-font-caption hb-text-night-light">{{
                              data.item.description
                            }}</span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </hb-select>
                  </hb-form>
                </span>
              </div>
            </div>
          </span>
        </div>
      </v-card-text>
      <hb-bottom-action-bar @close="_cancel">
        <template v-slot:left-actions>
          <help-juice />
        </template>
        <template v-slot:right-actions>
          <hb-btn :loading="updateLoader" @click="_save"> Save </hb-btn>
        </template>
      </hb-bottom-action-bar>
    </v-card>
  </v-dialog>
</template>
<script>
import api from "../../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "ManagePromos",

  mixins: [notificationMixin],

  data() {
    return {
      dialog: false,
      spaceGroupPromotions: [],
      defaultPromotions: [],
      filteredDiscounts: [],
      spaceGroupIdHash: "",
      updateLoader: false,
      initialLoading: false,
      config: {
        size: "",
        space_category: "",
        active: false,
        info: [],
      },
      resolvePromise: null,
      rejectPromise: null,
      promoInputs: {
        regular: {
          label: "Default Promotion",
          type: "default",
        },
        senior: {
          label: "Senior Discount",
          type: "discount",
        },
        military: {
          label: "Military Discount",
          type: "discount",
        },
        student: {
          label: "Student Discount",
          type: "discount",
        },
        // rent_now: {
        //   label: "Rent Now Promotion",
        //   type: "default",
        // }, // disabled for now
      },
      selectedPromos: {
        regular: "",
        senior: "",
        military: "",
        student: "",
        rent_now: "",
      },
    };
  },

  props: {
    property: {
      type: String,
      default: "",
    },
  },

  methods: {
    async show(configData) {
      this.initialLoading = true;
      Object.keys(this.selectedPromos).forEach((key) => {
        this.selectedPromos[key] = "";
      });
      this.config = Object.assign({}, this.config, configData);
      this.dialog = true;
      this.spaceGroupIdHash = configData?.spacegroup_id_hash;
      await this.getDefaultPromotions();
      await this.getSpaceGroupPromotions();
      this.initialLoading = false;
    },

    async _save() {
      let promotions = [];
      this.updateLoader = true;
      Object.keys(this.selectedPromos).forEach((key, index) => {
        if (this.selectedPromos[key])
          promotions.push({
            id: this.selectedPromos[key],
            website_promotion_type: key,
          });
      });
      await this.savePromotions({
        promotions: promotions,
      });
      this.updateLoader = false;
      this.dialog = false;
    },

    _cancel() {
      this.dialog = false;
      this.setPromotions();
    },

    getPromotionsByType(promotions = [], type) {
      return promotions.filter(
        (promo) => promo?.label === type && promo?.enable
      );
    },

    async getDefaultPromotions() {
      try {
        let response = (await api.get(this, api.PROMOTIONS))?.promotions ?? [];
        this.defaultPromotions = this.getPromotionsByType(
          response,
          "promotion"
        );
        this.filteredDiscounts = this.getPromotionsByType(response, "discount");
      } catch (err) {
        console.error(err);
        this.showMessageNotification({
          description: `Unable to fetch Promotions list.`,
        });
      }
    },

    async getSpaceGroupPromotions() {
      try {
        let response = await api.get(
          this,
          api.getPropertySpaceGroupPromotionsUrl(
            this.property,
            this.spaceGroupIdHash
          )
        );
        this.spaceGroupPromotions = response.promotions;
        await this.setPromotions();
      } catch (err) {
        console.error(err);
      }
    },

    async setPromotions() {
      this.spaceGroupPromotions.forEach((data, index) => {
        if (data?.website_promotion_type.length) {
          data?.website_promotion_type.forEach((type, index) => {
            this.selectedPromos[type] = data.id;
          });
        }
      });
    },

    async savePromotions(payload) {
      try {
        let response = await api.put(
          this,
          api.getPropertySpaceGroupPromotionsUrl(
            this.property,
            this.spaceGroupIdHash
          ),
          payload
        );
        this.showMessageNotification({
          type: "success",
          description: `Space Group Promotions updated successfully.`,
        });
      } catch (err) {
        console.error(err);
        this.showMessageNotification({
          description: `Unable to update Space Group Promotions.`,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.styled-form {
  border-right: 1px solid rgb(224 224 224);
  border-left: 1px solid rgb(224 224 224);
}

.size-detail {
  background-color: #f4f6f8;

  .space-category {
    text-transform: capitalize;
  }
}

.hb-modal-sub-header {
  min-height: 44px;
  // padding-top: 16px;
  // padding-bottom: 16px;
  font-size: 14px;
  color: #637381;
}

.hb-modal-content {
  font-size: 14px;
  color: #101318;
}

.hb-modal-header-close-padding-right {
  padding-right: 8px !important;
}

.hb-modal-content .v-input .v-label {
  line-height: 20px;
}

.hb-modal-title-font-size {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.hb-modal-title {
  min-height: 52px;
}

.v-card__actions.hb-modal-footer-wrapper {
  background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
  height: 60px;
  padding-left: 8px;
}

.rate-card-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px;

  @media (max-width: 1970px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1470px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.loader-card {
  width: 100%;
}
</style>