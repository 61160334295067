<template>
  <div class="rate-plans-wrapper my-4">
    <!-- skeleton loader while fetching the initial data -->
    <span class="rate-card-wrap pl-3 pt-8" v-if="initialDataLoading">
      <span v-for="n in 8" :key="n" class="loader-card">
        <v-skeleton-loader
          type="card-heading, list-item-two-line"
        ></v-skeleton-loader>
        <v-skeleton-loader type="image, image"></v-skeleton-loader>
      </span>
    </span>

    <!-- component which is displayed when the property has external rate engine -->
    <EmptyStateContainer
      v-else-if="!hasInternalRateEngine"
      :message="rateEngineMessage"
    >
    </EmptyStateContainer>

    <!-- component which is displayed when no rate plans available, has option for adding new rate plan -->
    <EmptyStateContainer
      v-else-if="hasNoRatePlans"
      :message="'There are no listed rate plans.'"
      showBtn
      btnTxt="Add Rate Plan"
      @clicked="showRatePlanForm = !showRatePlanForm"
    >
    </EmptyStateContainer>

    <!-- rate plan listing area with default rate plan and option to add new one -->
    <template v-else>
      <hb-notification
        v-model="notification"
        v-if="notification"
        title="Note:"
        type="caution"
      >
        Rate plans are managed on a company level so any changes you make at this level can affect all your properties.
      </hb-notification>
      <v-row class="mx-0">
        <v-col
          cols="12"
          class="d-flex justify-space-between align-center pb-3"
          :class="!notification ? 'pt-0' : 'pt-4'"
        >
          <span>
            <template v-if="defaultRatePlan">
              <span class="font-weight-medium">Company Default Plan:</span>
              {{ defaultRatePlan.name }}
            </template>
          </span>
          <hb-btn v-if="hasRolePermission('manage_rate_plan')" small color="secondary" @click="showRatePlanForm = !showRatePlanForm">
            Add New Rate Plan
          </hb-btn>
        </v-col>
      </v-row>
      <v-row class="rate-card-wrap px-6">
        <!-- Display the details of each rate plan -->
        <RateCard
          v-for="(rate, index) in rates"
          :key="index"
          :rate="rate"
          :rate_campaigns="rate_campaigns"
          :editPermission="hasRolePermission('manage_rate_plan')"
          :showDescriptiveIcons="true"
          @setDefault="setDefaultRatePlan"
          @fetchData="fetchData()"
        />
      </v-row>
    </template>

    <!-- form modal for create new rate plan -->
    <RatePlanForm
      v-if="showRatePlanForm"
      v-model="showRatePlanForm"
      :is-edit="!!initialRateSettings"
      :rate="initialRateSettings"
      :rate_campaigns="rate_campaigns"
      @submit="createRatePlan"
    />
  </div>
</template>
<script>
import RateCard from "./RateCard.vue";
import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";
import RatePlanForm from "./RatePlanForm.vue";
import api from "../../../assets/api.js";


import { mapMutations, mapState, mapActions, mapGetters } from "vuex"
import { EventBus } from "../../../EventBus.js"
import { cloneDeep, startCase } from "lodash";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "ManageRatePlans",
  mixins: [notificationMixin],
  data() {
    return {
      showRatePlanForm: false,
      notification: true,
      initialDataLoading: false,
      rate_campaigns: []
    };
  },
  async created() {
    this.initialDataLoading = true;
    if(!this.rates?.length) await this.fetchData()
    this.initialDataLoading = false;
    this.getRateCampaigns();
    this.fetchRatePlans();
  },
  computed: {
    ...mapState({
      rates: (state) => state.revManStore.rate.plans ?? [],
    }),
    ...mapGetters({
      defaultRatePlan: "revManStore/defaultRatePlan",
      companyRatePlanConf: "revManStore/companyRatePlanConf",
      hasRolePermission: "authenticationStore/rolePermission", 
      hasInternalRateEngine:
        "revManStore/hasInternalRateEngine",
      rateEngine: "revManStore/rateEngine",
    }),
    hasNoRatePlans() {
      return !this.rates.length;
    },
    rateEngineMessage() {
      return !this.hasInternalRateEngine ? `Since you are using ${startCase(this.rateEngine)}, you cannot access this page` : ''
    },
    initialRateSettings() {
      // TODO: Need to handle all this logic inside rate plan form
      let settings = {
        round_to: this.companyRatePlanConf?.round_to,
        settings: [
          {
            occupancy_percentage: 0,
            type: null,
            value: 0,
          },
        ],
        tags: []
      }
      if (Object.values(settings).filter(conf => !!conf).length) {
        return settings
      }
    }
  },
  components: { RateCard, RatePlanForm, EmptyStateContainer },
  methods: {
    ...mapMutations({
      setRatePlans: 'revManStore/SET_RATE_PLANS',
      updateCompanyRatePlanConf: 'revManStore/SET_COMPANY_RATE_PLAN_CONF',
    }),
    ...mapActions({
      fetchRatePlans: 'revManStore/fetchRatePlans'
    }),
    async getRateCampaigns(){
        let response = await api.get(this, api.COMPANY_RATE_CAMPAIGN);
        this.rate_campaigns = response?.rate_campaigns;
    }, 
    /**
     * Function to create new rate plan
     */
    async createRatePlan(value) {
      try {
        let response = await api.post(
          this,
          api.COMPANY_RATE_MANAGEMENT + "plans",
          value
        );
        this.setRatePlans(response)
        this.showMessageNotification({
          type: "success",
          description: `Rate Plan created successfully.`,
        });
      } catch (err) {
        console.error(err);
        this.showMessageNotification({
          description: err?.includes("already exists") ? err : `Unable to create Rate Plan.`,
        });
      }
    },

    /**
     * Function for fetching initial data.
     *  - Triggers API for fetching rate plans
     *  - Fetch the default rate plan for the company
     */
    async fetchData() {
      this.initialDataLoading = true;
      try {
        this.fetchRatePlans()
        EventBus.$emit("rateManagementByPropertyEvent", { event: 'refreshTable' })
      } catch (err) {
        console.error("Rate Plan fetch error", err);
        this.showMessageNotification({
          description: err,
        })
      }
      this.initialDataLoading = false;
    },

    /**
     * Function to set a rate plan as the default rate plan for the company
     *
     * @param {Object} Object which contains the id (id of the rate plan) and a callback function
     */
    async setDefaultRatePlan({ id, callback }) {
      try {
        let ratePlan = cloneDeep(this.companyRatePlanConf);
        ratePlan.default_rate_plan_id = id;
        await api.put(this, api.COMPANY_RATE_MANAGEMENT, ratePlan);
        this.updateCompanyRatePlanConf(ratePlan)
        this.setRatePlans()
        this.showMessageNotification({
          type: "success",
          description: `${this.defaultRatePlan.name} Plan has been set as the company default.`,
        });
        callback();
        EventBus.$emit("rateManagementByPropertyEvent", { event: 'refreshTable' })
      } catch (err) {
        console.error(err);
        this.showMessageNotification({
          description: `Unable to set default Rate Plan.`,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.rate-card-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 25px;
  @media (max-width: 1970px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1470px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}
.loader-card {
  width: 100%;
}
</style>