<template>
  <div class="value-pricing-wrapper">
    <!-- Skeleton loader when the data is fetched  -->
    <span class="skeletonloader-wrap" v-if="initialDataLoading">
      <template v-for="n in 3">
        <div :key="n" class="loader-item d-flex">
          <v-col cols="2" class="pr-0">
            <v-skeleton-loader type="image" max-height="85"></v-skeleton-loader>
          </v-col>
          <v-col cols="10" class="pl-0">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
        </div>
        <v-divider :key="'divider-' + n"></v-divider>
      </template>
    </span>

    <!-- 
      Displays messages in the following conditions
     - If no property is selected shows 'Select a facility to manage value based pricing configurations'
     - If Fetch API error occured, shows 'Oops! Something went wrong! Unable to fetch configurations for the selected facility.'
    -->
    <EmptyStateContainer v-else-if="showPlaceholder" :message="placeholderText">
    </EmptyStateContainer>

    <!-- Configuration Listing area -->
    <hb-card v-else class="ml-3 mr-1 my-6">
      <!-- Value Pricing Field : changes isActive property -->
      <hb-form
        label="Value Pricing"
        description="Use the Value Pricing system on the Company Website"
        required
      >
        <template v-slot:tooltipHeader> Value Pricing </template>
        <template v-slot:tooltipBody>
          By default, our value pricing feature selects the most affordable option<br>
          for a space size for the first tier, a median-priced option for the second<br>
          tier, and the most expensive option for the third tier.
        </template>

        <hb-radio-group v-model="form.valuePricing">
          <HbRadio label="Yes" :value="true" />
          <HbRadio label="No" :value="false" />
        </hb-radio-group>
      </hb-form>

      <!-- Value Tier Names Field : Radio group with 3 built in names and  one option for adding custom names -->
      <hb-form
        label="Value Pricing Tier Names"
        description=" Select the names your customers will see for your value pricing options"
        required
      >
        <div class="tier-row header-row mb-3 ml-8">
          <span>Value Tier 1: $</span>
          <span>Value Tier 2: $$</span>
          <span>Value Tier 3: $$$</span>
        </div>

        <hb-radio-group
          v-model="form.selectedTier"
          v-validate
          data-vv-name="tierName"
          data-vv-as="Value Tier Name"
          :error="errors.collect('tierName').length > 0"
        >
          <hb-radio v-for="(tier, key) in tierNames" :key="key" :value="key">
            <template v-slot:label>
              <div class="tier-row">
                <span>{{ tier.tier1 }}</span>
                <span>{{ tier.tier2 }}</span>
                <span>{{ tier.tier3 }}</span>
              </div>
            </template>
          </hb-radio>

          <!-- option for adding custom names -->
          <!-- <hb-radio value="custom">
            <template v-slot:label>
              <div class="tier-row">
                <HbTextField
                  v-model="customTierName.tier1"
                  v-validate
                  data-vv-name="customTierName"
                  data-vv-as="Custom Value Tier Name"
                  :error="errors.collect('customTierName').length > 0"
                  placeholder="[Input Name]"
                />
                <HbTextField
                  v-model="customTierName.tier2"
                  v-validate
                  data-vv-name="customTierName"
                  data-vv-as="Custom Value Tier Name"
                  :error="errors.collect('customTierName').length > 0"
                  placeholder="[Input Name]"
                />
                <HbTextField
                  v-model="customTierName.tier3"
                  v-validate
                  data-vv-name="customTierName"
                  data-vv-as="Custom Value Tier Name"
                  :error="errors.collect('customTierName').length > 0"
                  placeholder="[Input Name]"
                />
              </div>
            </template>
          </hb-radio> -->
        </hb-radio-group>

        <!-- option for adding custom names -->
        <hb-radio-group
          v-model="form.selectedTier"
          v-validate
          :class="{
            'error--text': errors.collect('customTierName').length > 0,
          }"
          :error="errors.collect('customTierName').length > 0"
        >
          <hb-radio value="custom">
            <template v-slot:label>
              <div class="tier-row">
                <HbTextField
                  v-model="customTierName.tier1"
                  v-validate
                  data-vv-name="customTierName"
                  data-vv-as="Custom Value Tier Name"
                  :error="errors.collect('customTierName').length > 0"
                  placeholder="[Your Custom Name]"
                />
                <HbTextField
                  v-model="customTierName.tier2"
                  v-validate
                  data-vv-name="customTierName"
                  data-vv-as="Custom Value Tier Name"
                  :error="errors.collect('customTierName').length > 0"
                  placeholder="[Your Custom Name]"
                />
                <HbTextField
                  v-model="customTierName.tier3"
                  v-validate
                  data-vv-name="customTierName"
                  data-vv-as="Custom Value Tier Name"
                  :error="errors.collect('customTierName').length > 0"
                  placeholder="[Your Custom Name]"
                />
              </div>
            </template>
          </hb-radio>
        </hb-radio-group>
      </hb-form>

      <!-- Value Tier Pricing Field: option for updating min_price_difference  -->
      <hb-form
        label="Custom Value Pricing"
        :description="alternateValuePricingText"
        full
        required
        v-if="hasInternalRateEngine"
      >
        <template v-slot:tooltipHeader>
          Custom Value Pricing
        </template>
        <template v-slot:tooltipBody>
          Custom value pricing allows you to select the exact prices that<br>
          you want your customers to see for every value pricing tier.
        </template>
        <div class="tier-pricing-row w-100">
          <div class="tier-col">
            <span class="heading">Value Tier 1: $</span>
            <div class="tier-pricing-item">
              Sell Rate
              <hb-tooltip>
                <template v-slot:item>
                  <hb-icon class="ml-2" small>mdi-information</hb-icon>
                </template>
                <template v-slot:body>
                  The rate quoted by a property for a space on Hummingbird
                </template>
              </hb-tooltip>
            </div>
          </div>
          <div class="tier-col">
            <span class="heading">Value Tier 2: $$</span>
            <div class="tier-pricing-item">
              <span> Sell Rate + </span>
              <HbTextField
                v-model="tierPricing.tier2"
                v-validate
                data-vv-name="tierPricing"
                data-vv-as="Value Tier Pricing"
                class="tier-pricing-field"
                :error="errors.collect('tierPricing').length > 0"
                placeholder="0"
                :maxlength="decimalPointLimit"
                @input="decimalPointLimiter(tierPricing.tier2)"
              />
              %
            </div>
          </div>
          <div class="tier-col">
            <span class="heading">Value Tier 3: $$$</span>
            <div class="tier-pricing-item">
              <span> Sell Rate + </span>
              <HbTextField
                v-model="tierPricing.tier3"
                v-validate
                data-vv-name="tierPricing"
                data-vv-as="Value Tier Pricing"
                class="tier-pricing-field"
                :error="errors.collect('tierPricing').length > 0"
                placeholder="0"
                :maxlength="decimalPointLimit"
                @input="decimalPointLimiter(tierPricing.tier3)"
              />
              %
            </div>
          </div>
        </div>
      </hb-form>

      <hb-bottom-action-bar @close="cancel" :topBorder="false">
        <template v-slot:right-actions>
          <hb-btn
            :disabled="!isDirty"
            @click="postValuePricingConfigurations"
            :loading="updateDataLoading"
          >
            Save
          </hb-btn>
        </template>
      </hb-bottom-action-bar>
    </hb-card>
  </div>
</template>
<script>
//utils
import { cloneDeep, isEqual, isEqualWith } from "lodash";
import api from "../../../assets/api.js";
import { mapGetters } from "vuex";

//components
import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";

//mixins
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "ValueBasedPricing",
  mixins: [notificationMixin],
  data() {
    return {
      form: {
        valuePricing: false,
        selectedTier: 0,
      },
      alternateValuePricingText:
        "Only edit this section if you would like to manually set the price that you want your customers to see for your value pricing options.",
      tierNames: [
        {
          tier1: "Good",
          tier2: "Better",
          tier3: "Best",
        },
        {
          tier1: "Economy",
          tier2: "Standard",
          tier3: "Premium",
        },
        {
          tier1: "Standard",
          tier2: "Premium",
          tier3: "Deluxe",
        },
      ],
      customTierName: {
        tier1: "",
        tier2: "",
        tier3: "",
      },
      tierPricing: {
        tier1: 0,
        tier2: 0,
        tier3: 0,
      },
      initialTierPricing: null,
      initialCustomTierName: null,
      initialData: null,
      valuePricingDataApiResponse: null,
      initialDataLoading: false,
      updateDataLoading: false,
      decimalPointLimit: ""
    };
  },
  watch: {
    /**
     * Watches property_id to reset validation and fetch initial data.
     */
    property: {
      immediate: true,
      handler(value) {
        this.$validator.reset();
        this.$validator.errors.clear();
        if (value) this.fetchValuePricingConfigurations();
      },
    },
  },
  components: { EmptyStateContainer },
  computed: {
    ...mapGetters({
      property: "revManStore/getSelectedProperty",
      hasInternalRateEngine: "revManStore/hasInternalRateEngine",
    }),
    /**
     * Checks if the form object is editted for the current level.
     *
     * @returns {Boolean} - true if current level in form object is editted, else false
     */
    isDirty() {
      if (!isEqual(this.initialData, this.form)) return true;
      if (
        this.form.selectedTier == "custom" &&
        !isEqual(this.initialCustomTierName, this.customTierName)
      ) 
        return true;
      if (!isEqualWith(this.initialTierPricing, this.tierPricing, (a, b) =>{
        return (a.tier1 == b.tier1 && a.tier2 == b.tier2 && a.tier3 == b.tier3)
      })) return true;
      return false;
    },

    /**
     * To return the settings for tier 1 from the fetched response
     *
     * @returns {Object} settings of tier 1
     */
    settingsTier1() {
      return this.valuePricingDataApiResponse?.settings?.find(
        (setting) => setting?.type == "good"
      );
    },

    /**
     * To return the settings for tier 2 from the fetched response
     *
     * @returns {Object} settings of tier 2
     */
    settingsTier2() {
      return this.valuePricingDataApiResponse?.settings?.find(
        (setting) => setting?.type == "better"
      );
    },

    /**
     * To return the settings for tier 3 from the fetched response
     *
     * @returns {Object} settings of tier 3
     */
    settingsTier3() {
      return this.valuePricingDataApiResponse?.settings?.find(
        (setting) => setting?.type == "best"
      );
    },
    /**
     * Hide/show empty state container according return value
     * @returns {Boolean} A boolean value
     */
    showPlaceholder() {
      return (
        !this.property ||
        (!this.initialDataLoading && !this.valuePricingDataApiResponse)
      );
    },

    /**
     * A message for empty state container
     * message string may change according to the conditional check
     * @returns {String} message for placeholder
     */
    placeholderText() {
      let message =
        "Oops! Something went wrong! Unable to fetch configurations for the selected property.";
      if (!this.property)
        message =
          "Select a property to manage value based pricing configurations";
      return message;
    },
  },
  methods: {
    /**
     * Function for Limit to enter only 2 decimal points number into input field
     */
    decimalPointLimiter(rate) {
      this.decimalPointLimit = ''
      if (rate.toString()?.includes(".")) {
        rate = parseFloat(rate).toFixed(2)
        this.decimalPointLimit = rate.length
      }
    },    
    
    /**
     * Function for initializing the initial data.
     *  - mapping data from API response to the component data
     *  - Make copies of all data to watch for changes
     *  - resets custom tier name and pricing fields
     */
    initializeData(response) {
      this.valuePricingDataApiResponse = response;
      this.form.valuePricing = response.active;
      this.customTierName = {
        tier1: "",
        tier2: "",
        tier3: "",
      };
      this.tierPricing = {
        tier1: 0,
        tier2: 0,
        tier3: 0,
      };
      if (response.settings) {
        this.mapValueTierNames();
        this.mapValueTierPricing();
      } else this.form.selectedTier = 0;
      this.initialData = cloneDeep(this.form);
      this.initialCustomTierName = cloneDeep(this.customTierName);
      this.initialTierPricing = cloneDeep(this.tierPricing);
    },

    /**
     * Function to map the Tier name provided by the API to the component data
     *  - Checks if the given names are same as any one of the 3 built in names.
     *  - If they don't match, will be set to custom one and fill the text box with provided label
     */
    mapValueTierNames() {
      let itemIndex = this.tierNames.findIndex((group, index) => {
        if (
          group.tier1.toLowerCase() ==
            this.settingsTier1?.label?.toLowerCase() &&
          group.tier2.toLowerCase() ==
            this.settingsTier2?.label?.toLowerCase() &&
          group.tier3.toLowerCase() == this.settingsTier3?.label?.toLowerCase()
        ) {
          return true;
        }
        return false;
      });

      if (itemIndex >= 0) this.form.selectedTier = itemIndex;
      else {
        this.form.selectedTier = "custom";
        this.customTierName.tier1 = this.settingsTier1.label;
        this.customTierName.tier2 = this.settingsTier2.label;
        this.customTierName.tier3 = this.settingsTier3.label;
      }
    },

    /**
     * Function to map the minimum price difference from API to tier pricing fields.
     */
    mapValueTierPricing() {
      this.tierPricing.tier2 = this.settingsTier2.min_price_difference;
      this.tierPricing.tier3 = this.settingsTier3.min_price_difference;
    },
    /**
     * Fetch Value Pricing configurations.
     *  - Triggers APi for fetching value pricing configurations
     *  - Sets initial data from API response
     */
    async fetchValuePricingConfigurations() {
      this.initialDataLoading = true;
      try {
        let response = await api.get(
          this,
          api.getPropertyRateManagementUrl(this.property) + "value-pricing/"
        );
        this.initializeData(response);
      } catch (err) {
        this.showMessageNotification({
          description: `Unable to fetch Value Pricing configurations for the facility.`,
        });
        console.error("Value Pricing configuration fetch error", err);
      }
      this.initialDataLoading = false;
    },

    /**
     * Custom function for validating the fields.
     * - If custom tier name is selected.. 3 tier names are required
     * - Sell rate (min price difference):
     *   - can be same for all tier
     *   - best should be equal or greated than better
     */
    validateFields() {
      if ([0, 1, 2, "custom"].includes(this.form.selectedTier)) {
        if (this.form.selectedTier == "custom") {
          if (
            Object.values(this.customTierName).some(
              (x) => x === null || x === ""
            )
          ) {
            this.$validator.errors.add({
              field: "customTierName",
              msg: "Custom Value Tier Names field is required",
            });
          } else if (
            this.tierNames.some((tier) => isEqual(tier, this.customTierName))
          ) {
            this.$validator.errors.add({
              field: "customTierName",
              msg: "Custom Value Tier Names cannot be same as default names",
            });
          } else if (
            new Set(Object.values(this.customTierName)).size !==
            Object.values(this.customTierName).length
          ) {
            this.$validator.errors.add({
              field: "customTierName",
              msg: "Custom Value Tier Names cannot be duplicate",
            });
          }
        }
      }

      let { tier2: tier2Price, tier3: tier3Price } = this.tierPricing;

      if (
        (tier2Price || tier2Price === 0) &&
        (tier3Price || tier3Price === 0)
      ) {
        if (isNaN(tier2Price) || isNaN(tier3Price)) {
          this.$validator.errors.add({
            field: "tierPricing",
            msg: "Tier Pricing field value should be numeric.",
          });
        } else if (!(tier2Price == 0 && tier3Price == 0)) {
          if (!(Number(tier3Price) > Number(tier2Price))) {
            this.$validator.errors.add({
              field: "tierPricing",
              msg: "Tier 3 field value should be greater than Tier 2 field.",
            });
          }
        }
      } else {
        this.$validator.errors.add({
          field: "tierPricing",
          msg: "Tier Pricing field is required",
        });
      }
    },

    /**
     * Post Value Pricing configurations.
     *  - Checks for any validation errors
     *  - Map component data to the API data format
     *  - Triggers Put API for value pricing configurations.
     */
    async postValuePricingConfigurations() {
      this.updateDataLoading = true;
      const valid = await this.$validator.validateAll();
      await this.validateFields();
      if (!valid || this.errors.items.length) {
        console.log("not valid");
        this.updateDataLoading = false;
        return;
      }
      let data = this.valuePricingDataApiResponse;
      data.active = this.form.valuePricing;

      let selectedSettings =
        this.form.selectedTier != "custom"
          ? this.tierNames[this.form.selectedTier]
          : this.customTierName;
      if (data.settings) {
        data.settings.forEach((setting) => {
          if (setting.type == "good") setting.label = selectedSettings.tier1;
          else if (setting.type == "better") {
            setting.label = selectedSettings.tier2;
            setting.min_price_difference = this.tierPricing.tier2;
          } else {
            setting.label = selectedSettings.tier3;
            setting.min_price_difference = this.tierPricing.tier3;
          }
        });
      } else {
        data["settings"] = [
          {
            type: "good",
            label: selectedSettings.tier1,
            min_price_difference: 0,
          },
          {
            type: "better",
            label: selectedSettings.tier2,
            min_price_difference: this.tierPricing.tier2,
          },
          {
            type: "best",
            label: selectedSettings.tier3,
            min_price_difference: this.tierPricing.tier3,
          },
        ];
      }

      try {
        await api.put(
          this,
          api.getPropertyRateManagementUrl(this.property) + "value-pricing/",
          data
        );
        this.initializeData(this.valuePricingDataApiResponse);
        this.showMessageNotification({
          type: "success",
          description: `Value Pricing configurations updated successfully`,
        });
      } catch (err) {
        this.showMessageNotification({
          description: `Unable to submit Value Pricing configuration`,
        });
        console.error("Unable to submit Value Pricing configuration", err);
      }
      this.updateDataLoading = false;
    },

    /**
     * Function to revert the form to its original state.
     */
    cancel() {
      this.form = cloneDeep(this.initialData);
      this.customTierName = cloneDeep(this.initialCustomTierName);
      this.tierPricing = cloneDeep(this.initialTierPricing);
    },
  },
};
</script>
<style lang="scss">
.value-pricing-wrapper {
  .hb-aviary-form-padding-content {
    background-color: white;
  }
}
</style>
<style lang="scss" scoped>
.tier-row {
  width: 500px;
  display: flex;
  justify-content: space-between;
  &.header-row {
    color: #637381;
    font-size: 12px;
  }
  span,
  .hb-text-field-wrapper {
    width: 30% !important;
    margin: 0;
  }
}
.tier-pricing-row {
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
  width: 500px;
  .tier-col {
    width: 30%;
    .heading {
      color: #637381;
      font-size: 12px;
    }
    .tier-pricing-item {
      display: flex;
      gap: 5px;
      align-items: start;
      min-height: 30px;
      margin-top: 5px;
      .tier-pricing-field {
        max-width: 60px;
      }
    }
  }
}
</style>