<template>
    <hb-modal @close="$emit('close')" v-model="showManageRateCampaign" size="large" title="Create A Rate Campagin">
        <template v-slot:subheader>Create the rules and effects of the rate Campagin</template>

        <template v-slot:content>
            <hb-form 
                label="Name"
                required
                description="Provide a name for the Promo Rate (Internal)"
            >
                <HbTextField
                    v-model="form.name"
                    v-validate="'required|max:45'"
                    data-vv-scope="default"
                    data-vv-name="name"
                    data-vv-as="Name"
                    :error="errors.has('default.name')"
                />
            </hb-form>

            <hb-form 
                label="Description"
                required
                description="Provide a brief description of the promotion/discount"
            >
                <HbTextarea
                    v-model="form.description"
                    v-validate="'required|max:255'"
                    data-vv-scope="default"
                    data-vv-name="description"
                    data-vv-as="Description"
                    :error="errors.has('default.description')"
                />
            </hb-form>

            <hb-form 
                label="Occupancy Threshold"
                required
                description="Apply Promo Rate if Occupancy is equal or below"
            >
                <HbTextField
                    box
                    condensed
                    medium
                    v-model="form.occupancy_threshold"
                    v-validate="'required|max:255'"
                    data-vv-scope="default"
                    data-vv-name="occupancy_threshold"
                    data-vv-as="Occupancy Threshold"
                    :error="errors.has('default.occupancy_threshold')"
                />
            </hb-form>

            <hb-form 
                label="Rate"
                required
                description="Choose the method for your Promo Rate"
            >

                <HbSelect
                    v-model="form.rate_method"
                    :items="rate_methods"
                    item-value="value"
                    item-text="label"
                    v-validate="'required'"
                    data-vv-scope="default"
                    data-vv-name="description"
                    data-vv-as="Rate Method"
                    :clearable="false"
                    :error="errors.has('default.rate_method')"
                />


                <HbTextField
                    box
                    condensed
                    medium
                    v-model="form.rate"
                    v-validate="'required|max:255'"
                    data-vv-scope="default"
                    data-vv-name="rate"
                    data-vv-as="Rate"
                    :error="errors.has('default.rate')"
                />

            </hb-form>

 
             <hb-form 
                label="Time Based Overrides"
                description="Apply promo rate overrides based on time (promotions end at midnight)."
            >   
                <HbCheckbox label="Days of the week" v-model="use_days_overrides"></HbCheckbox>
                <div class="ml-8" v-if="use_days_overrides">
                    <HbCombobox
                        full    
                        v-model="form.days_overrides"
                        :items="CALENDAR.DAYS_LIST"
                        select
                        item-text="name"
                        item-value="value"
                        data-vv-scope="default"
                        data-vv-name="days_overrides"
                        data-vv-as="days_overrides"
                        :error="errors.has('default.days_overrides')"
                        label="Select Days"
                    />

                     <HbCheckbox label="Offer promotion rate regardless of occupancy threshold" v-model="form.days_promo_rate_occupancy_override"></HbCheckbox>
                </div>
                
                <HbCheckbox label="Month" v-model="use_months_overrides"></HbCheckbox>
            
                <div class="ml-8" v-if="use_months_overrides">
                    <HbCombobox
                        v-model="form.months_overrides"
                        :items="CALENDAR.MONTHS_LIST"
                        select
                        item-text="name"
                        item-value="value"
                        full   
                        data-vv-scope="default"
                        data-vv-name="months_overrides"
                        data-vv-as="months_overrides"
                        :error="errors.has('default.months_overrides')"
                        label="Select Months"
                    />

                     <HbCheckbox label="Offer promotion rate regardless of occupancy threshold" v-model="form.months_promo_rate_occupancy_override"></HbCheckbox>
                </div>
            </hb-form>
            

            <hb-form 
                label="Value Tiers"
                description="Apply rate campaigns to the following Value Tiers only"
            >
   

                <v-row>
                    <v-col>
                        <v-checkbox
                        
                            v-model="form.value_tiers"
                            label="Good"
                            value="good"
                            data-vv-scope="default"
                            data-vv-name="value_tiers_good"
                            data-vv-as="Good Value Tier"
                            :error="errors.has('default.value_tiers_good')"
                          >  
                        </v-checkbox>

                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="form.value_tiers"
                            label="Better"
                            value="better"
                            data-vv-scope="default"
                            data-vv-name="value_tiers_better"
                            data-vv-as="Better Value Tier"
                            :error="errors.has('default.value_tiers_better')"
                            
                         >   
                        </v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox
                            v-model="form.value_tiers"
                            label="Best"
                            value="best"
                            data-vv-scope="default"
                            data-vv-name="value_tiers_best"
                            data-vv-as="Best Value Tier"
                            :error="errors.has('default.value_tiers_best')"
                         >  
                        </v-checkbox>
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </hb-form>


            <!-- <hb-form 
                label="Conditions"
                description="Instructions"
            >
                <v-checkbox
                    v-model="form.conditions"
                    label="Rent Now Only"
                    value="rent_now"
                    data-vv-scope="default"
                    data-vv-name="conditions_rent_now"
                    data-vv-as="Rent Now Only"
                    :error="errors.has('default.conditions_rent_now')"
               ></v-checkbox>



            </hb-form> -->
            
            <hb-form 
                label="Rent Increase Plan"
                clearable
                description="Apply a Rent Plan to tenants that come in uner this Promo Rate"
            >
                <HbSelect
                    v-model="form.rent_management_plan_id"
                    class="mb-10"
                    :items="rentPlans"
                    item-value="id"
                    item-text="name"
                    data-vv-scope="default"
                    data-vv-name="rent_management_plan_id"
                    data-vv-as="Rent Increase Plan"
                    :error="errors.has('default.rent_management_plan_id')"
                />


            </hb-form>




        </template>

         <template v-slot:actions>
                <hb-btn color="primary" @click="saveRateCampaign">Save</hb-btn>
            </template>


    </hb-modal> 
</template>


<script>
    import CALENDAR from '@/constants/calendar.js';
    
    import { mapGetters, mapActions, mapState } from "vuex";
    import api from "../../../assets/api.js";
    //mixins
    import { notificationMixin } from "@/mixins/notificationMixin.js";
    export default {
        name: "ManageRateCampaign",
        data() {
            return { 
                CALENDAR: {},
                showManageRateCampaign: true,
                rate_methods: [{
                    value: 'percent_of_sellrate',
                    label: '% of Sellrate'
                }],
                
                use_days_overrides: false, 
                use_months_overrides: false, 
                form: {
                    id: '',
                    name: '',
                    description: '',
                    occupancy_threshold: '',
                    rate_method: 'percent_of_sellrate',
                    rate: '',
                    // holiday_overrides: [],
                    // holiday_promo_offset: '',
                    // holiday_promo_rate_occupancy_override: false,
                    days_overrides: [],
                    days_promo_rate_occupancy_override: false,
                    months_overrides: [],
                    months_promo_rate_occupancy_override: false,
                    value_tiers: [],
                    conditions: [],
                    rent_management_plan_id: '',
                }
            }
        },
        props: ['selected'], 
        mixins: [notificationMixin],
        computed:{
             ...mapGetters({
            
                property: "revManStore/getSelectedProperty",
            }),
            ...mapState({
                rentPlans: (state) => state.revManStore.rent.plans ?? []
            }),
            
        }, 
        created(){
            if(this.selected?.id){
                let { created_by, created_at, modified_at, CreatedBy, RentManagementPlan,  ...selected } = this.selected
                this.form = selected;
                if(this.form.days_overrides.length){
                    this.use_days_overrides = true;
                }
                if(this.form.months_overrides.length){
                    this.use_months_overrides = true;
                }
            }
            this.CALENDAR = CALENDAR;
            this.getRentPlans();
        },
        methods: {
            ...mapActions({
                fetchRentPlans: "revManStore/fetchRentPlans",
            }),
            async saveRateCampaign(){
                try {
                    
                    
                    this.form.days_overrides = this.form.days_overrides.map(d => d.constructor === Object ? d.value: d ); 
                    this.form.months_overrides = this.form.months_overrides.map(m =>  m.constructor === Object ? m.value: m );
                    
                    if(!this.use_days_overrides){
                        this.form.days_overrides = []
                    }

                    if(!this.use_months_overrides){
                        this.form.months_overrides = []
                    }
                    this.form.days_promo_rate_occupancy_override = !!this.form.days_promo_rate_occupancy_override;
                    this.form.months_promo_rate_occupancy_override = !!this.form.months_promo_rate_occupancy_override;

                    if(this.form.id){
                        let response = await api.put(this, api.COMPANY_RATE_CAMPAIGN + `${this.form.id}`, {
                        // property_id: this.property, 
                        ...this.form
                    });
                    } else {
                        let response = await api.post(this, api.COMPANY_RATE_CAMPAIGN, {
                            // property_id: this.property, 
                            ...this.form
                        });
                    }
                    this.showMessageNotification({
                        type: "success", 
                        description: `${this.form.name} Rate Campaign has been saved`,
                    })
                    this.$emit('saved');
            } catch (err) {
                console.log("Err", err); 
                this.showMessageNotification({
                    type: "error",
                    title: `Save failed`,
                    description: err,
                });
            }


            },
            async getRentPlans(){
                /**
                    * Function for fetch all rent plan list.
                */
    
                try {
                    await this.fetchRentPlans();
                } catch (err) {
                    console.error("Rent plan fetch error", err);
                    this.showMessageNotification({
                        description: "Rent Plans fetch failed.",
                    });
                }
    
            }
        }
}
</script>
