<script type="text/babel">
import { EventBus } from "../../../EventBus.js";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "RateManagementByPropertyActions",
  props: {
    propertyId: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      hasRolePermission: "authenticationStore/rolePermission",
      hasInternalRateEngine:
        "revManStore/hasInternalRateEngine",
    }),
    items() {
      let rate_plan_id = this.params.data.spacegroup_rate_plan_id;
      let rate_plan_status = this.params.data.spacegroup_rate_management_active;
      let statusLabel = { true: "Enable Rate Management", false: "Disable Rate Management" }[
        !Boolean(rate_plan_status)
      ];
      return [
        ...(this.hasRolePermission("assign_rate_plan") &&
        this.hasInternalRateEngine
          ? [
              {
                text: "Assign Rate Plan",
                event: "assignRatePlan",
              },
            ]
          : []),

        {
          text: "View Spaces",
          event: "viewSpaces",
        },
        // Inlude toggle only if rate plan exists
        ...(rate_plan_id && this.hasInternalRateEngine
          ? [
              {
                text: statusLabel,
                event: "toggleProfileStatus",
              },
            ]
          : []),
        {
          text: "Assign Promotions",
          event: "managePromos",
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setSpaceData: "revManStore/SET_SPACE_DATA",
    }),
    triggerEvent(event) {
      if (event == "viewSpaces") {
        this.setSpaceData(
          {
            ...this.params.data,
            ...{ propertyId: this.propertyId }
          }
        );
      } else
        EventBus.$emit("rateManagementByPropertyEvent", {
          event,
          data: this.params.data,
        });
    },
  },
};
</script>
    
<template>
  <div>
    <!-- Populate Menu items -->
    <v-card class="mx-auto" max-width="500">
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            @click="triggerEvent(item.event)"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <!-- Populate Menu items -->
  </div>
</template>
    