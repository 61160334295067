<template>
  <div class="rate-plan-list">
    <RoundingForm label="Rounding To" noDescription v-model="rate.round_to" read-only/>
  </div>
</template>
<script>
import RoundingForm from "../../assets/RoundingForm.vue";
export default {
  name: "PlanSettings",
  props: {
    rate: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {
    RoundingForm,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
</style>