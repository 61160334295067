<template>
  <div class="rate-setting-wrap d-flex justify-space-between">
    <div class="rate-setting-content d-flex">
      <span>If occupancy is ≥</span>
      <div class="rm-digit-field">
        <hb-text-field
          v-model="ratePlan.occupancy_percentage"
          v-validate
          :error="error.occupancy"
          placeholder="0"
          type="number"
          :hide-spin-buttons="true"
          :rules="[rules.percentRule, rules.wholeNumberRule]"
          min="0"
          max="99"

        />
      </div>
      %
      <div class="rm-select-field">
        <HbSelect
          v-model="ratePlan.type"
          :items="ratePlanTypes"
          item-text="label"
          item-value="value"
          v-validate
          key="type"
          :error="error.type"
          placeholder="Select"
          :clearable="false"
        />
      </div>
      <span>rate by <template v-if="method == 'dollar'">$</template></span>
      <div class="rm-digit-field">
        <hb-text-field
          v-if="method != 'dollar'"
          v-model="ratePlan.value"
          v-validate
          key="rateByPercent"
          :error="error.value"
          type="number"
          name="rateByPercent"
          placeholder="0"
          :rules="[rules.percentRule, rules.wholeNumberRule]"
          max="100"
          min="1"
        />
        <hb-text-field
          v-else
          v-model="ratePlan.value"
          v-validate
          key="rateByDollar"
          :error="error.value"
          type="text"
          name="rateByDollar"
          placeholder="0"
          :maxlength="decimalPointLimit"
          @input="decimalPointLimiter(ratePlan.value)"
        />
      </div>
      <template v-if="method != 'dollar'">%</template>
    </div>
    <hb-link v-if="remove" @click="$emit('remove', index)">Remove</hb-link>
  </div>
</template>
<script>
export default {
  name: "RateSetting",
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
    },
    remove: {
      type: Boolean,
    },
    index: {
      type: Number,
    },
    method: {
      type: String,
      default: "dollar",
    },
  },
  computed: {
    ratePlan: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
      isNotDollar() {
        return this.method != 'dollar'
      }
    },
  },
  data() {
    return {
      rules: {
        percentRule: val => {
          if(val > 100) return 'btwn 0-100 is accepted'
          return true
        },
        wholeNumberRule: val => {
          return (Number(val) % 1) === 0
        },
      },
      ratePlanTypes: [
        {
          value: "increase",
          label: "Increase",
        },
        {
          value: "decrease",
          label: "Decrease",
        },
      ],
      error: {
        occupancy: false,
        type: false,
        value: false,
      },
      decimalPointLimit: ''
    };
  },
  methods: {
    showError(value) {
      this.error = value;
    },
    decimalPointLimiter(value) { // limit decimal point of dollar to 2 
      if(value?.length == 1) this.decimalPointLimit = ''
      if (!this.decimalPointLimit) {
        if (typeof value?.includes === 'function' && value?.includes(".")) {
          this.decimalPointLimit = value.length + 2
        }
      }
      console.log(this.decimalPointLimit, " .  decimalPoint")
    }
  },
};
</script>
<style lang="scss" scoped>
.rm-digit-field {
  width: 42px;
}
.rm-select-field {
  width: 100px;
}
.rate-setting-content {
  gap: 5px;
} 
</style>