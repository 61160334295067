<template>
  <div class="space-scoring-wrapper">
    <!-- Skeleton loader when the data is fetched  -->
    <span class="skeletonloader-wrap" v-if="initialDataLoading">
      <template>
        <div :key="n" class="loader-item d-flex">
          <v-col cols="1" class="pr-0">
            <v-skeleton-loader
              v-for="n in 7"
              :key="n"
              type="image"
              max-height="85"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="11" class="pl-0 mt-2">
            <v-skeleton-loader
              v-for="n in 8"
              :key="n"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </v-col>
        </div>
      </template>
    </span>

    <div v-else class="pt-4">
      <div>
        <v-col cols="12" class="pt-0 pb-1 hb-text-light d-flex align-center">
          <span class="mb-2">
            Arrange the order of Features and Amenities by importance or
            desirability. Drag and drop
          </span>
          <hb-icon class="vertical-align-middle">mdi-drag-custom</hb-icon>
          <span class="mb-2">each row. </span>
        </v-col>
        <!-- TABS -->
        <div ref="rmTabSection">
          <hb-tabs v-model="activeTab" class="mt-n2">
            <v-tab v-for="item in tabOptions" :key="item.key">
              {{ getUnitTypeTitle({unit_type_id:item}) }}
            </v-tab>
          </hb-tabs>
          <v-divider></v-divider>
        </div>
        <!-- TAB ITEMS -->
         
        <v-tabs-items class="rm-tab-items" v-model="activeTab">


          <v-tab-item 
            class="tab-item"
            v-for="(item,i) in amenities"
            :key="i"
          >
              <hb-card class="mx-2 mt-4" v-if="item.length">
                <hb-form
                  label="Feature Hierarchy"
                  full
                  class="draggable-features"
                  :divider="false"
                >
                  <div
                    class="d-flex mx-0 hb-form-description-text-night-light mb-3 mt-n3 flex: 1" 
                  >
                    <v-col class="mb-0" style="flex: 1, 0">
                      <span>
                        Rank your features and amenities based on their importance and desirability. The order that you see on this screen is the same order that your customers will see on your website.
                      </span>
                    </v-col>

                    <v-col class="mb-0 align-right d-flex align-start justify-end px-2" style="flex: 0 1 170px">
                      <span class="">{{ verbages.show_in_filter_bar.title }}</span>
                      <hb-tooltip>
                        <template v-slot:item>
                          <hb-icon class="ml-2 custom-info-icon" small>mdi-information</hb-icon>
                        </template>
                        <template v-slot:header>
                          {{ verbages.show_in_filter_bar.tooltip }} 
                        </template>
                      </hb-tooltip>
                  </v-col>

                  
                    <v-col class="mb-0 align-right d-flex align-start justify-end px-2" style="flex: 0 1 140px">
                      <span class="">{{ verbages.show_in_website.title }}</span>
                      <hb-tooltip>
                        <template v-slot:item>
                          <hb-icon class="ml-2 custom-info-icon" small>mdi-information</hb-icon>
                        </template>
                        <template v-slot:body>
                          {{ verbages.show_in_website.tooltip }}
                        </template>
                      </hb-tooltip>
                    </v-col>
                  </div>
                  <DraggableList :list="item" />
                </hb-form>
                <hb-bottom-action-bar
                  @close="cancel"
                >
                  <template v-slot:right-actions>
                    <hb-btn
                      :disabled="!isDirty"
                      @click="postSpaceScoring()"
                      :loading="updateDataLoading"
                    >
                      Save
                    </hb-btn>
                  </template>
                </hb-bottom-action-bar>
              </hb-card>
              <EmptyStateContainer v-else class="mt-4" :message="placeholderText"></EmptyStateContainer>
          </v-tab-item>


        
        </v-tabs-items>
      </div>

    </div>
  </div>
</template>

<script>
import DraggableList from "../utils/DraggableList.vue";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import api from "../../../assets/api.js";
import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";

//mixins
import { notificationMixin } from "@/mixins/notificationMixin.js";
import activity from "../../../assets/api/activity.js";
export default {
  components: {
    DraggableList,
    EmptyStateContainer
  },
  mixins: [notificationMixin],
  data() {
    return {
      amenities: null,
      initialData: null,
      activeTab: 0,
      verbages: {
        show_in_website: {
          title: 'Show in Filter Dropdown',
          tooltip: 'Display as an option to filter on the website'
        },
        show_in_filter_bar: {
          title: 'Show in Filter Bar (max 5 items)',
          tooltip: 'Display as an option to filter on the website'
        },

      },
      tabOptions:[],
      initialDataLoading: false,
      updateDataLoading: false,
    };
  },
  watch: {
    /**
     * Watches property_id to reset validation and fetch initial data.
     */
    property: {
      immediate: true,
      handler(value) {
        if (value) this.fetchSpaceScore();
      },
    },
    amenities: {
      deep: true,
      handler(n, o) {
        let length = n[this.tabOptions[this.activeTab]].filter(a => a.show_in_filter_bar).length;

        if(length <= 5) return;
        this.showMessageNotification({
          type: "error",
          title: `Maximum Items Exceeded`,
          description: `You can select a maximum of 5 Features and Amenities for display in the Filter Bar. In order to select one more you need to deselect an existing one.`,
        });

      },
    }
  },
  computed: {
    ...mapGetters({
      property: "revManStore/getSelectedProperty",
    }),
    currentType() {
      const unitTypeMeta = this.getUnitTypeMeta({unit_type_id:this.tabOptions[this.activeTab]});
      return unitTypeMeta?.display_name ;
    },
    isDirty() {
      if (!this.currentType) return false;
      return (
        JSON.stringify(this.initialData[this.tabOptions[this.activeTab]]) !==
        JSON.stringify(this.amenities[this.tabOptions[this.activeTab]])
      );
    },
    placeholderText() {
      return `There are no amenities configured for the ${this.currentType} space type`
    }
  },
  methods: {
    cancel() {
      this.amenities = cloneDeep(this.initialData);
    },
    /**
     * Fetch Space scoring data.
     *  - Triggers API for fetching space scoring data
     *  - Sets initial data from API response
     */
    async fetchSpaceScore() {
      this.initialDataLoading = true;
      try {
        let response = await api.get(
          this,
          api.getPropertyRateManagementUrl(this.property) + "space-score"
        );
        this.amenities = response.amenities;
        this.initialData = cloneDeep(this.amenities);
        this.tabOptions = Object.keys(response?.amenities) || [];
      } catch (err) {
        this.showMessageNotification({
          description: `Unable to fetch Space Score for the facility.`,
        });
      }
      this.initialDataLoading = false;
    },

    /**
     * Post Space scoring data.
     *  - Triggers Put API for value pricing configurations.
     */
    async postSpaceScoring() {
      
      try {

        if(this.amenities[this.tabOptions[this.activeTab]].filter(a => a.show_in_filter_bar).length > 5) {
          this.showMessageNotification({
            type: "error",
            title: `Maximum Items Exceeded`,
            description: `You can select a maximum of 5 Features and Amenities for display in the Filter Bar. In order to select one more you need to deselect an existing one.`,
          });
          return;
        }

        this.updateDataLoading = true;


        let data = this.amenities[this.tabOptions[this.activeTab]].map((amenity, index) => {
          amenity.sort_order = index + 1;
          return amenity;
        });
        let response = await api.put(
          this,
          api.getPropertyRateManagementUrl(this.property) +
            "space-score/" +
            this.tabOptions[this.activeTab],
          data
        );
        this.amenities[this.tabOptions[this.activeTab]] = response?.amenities?.[this.tabOptions[this.activeTab]] ?? [];
        this.initialData[this.tabOptions[this.activeTab]] = cloneDeep(
          this.amenities[this.tabOptions[this.activeTab]]
        );

        this.showMessageNotification({
          type: "success",
          description: `Space Score updated successfully.`,
        });
      } catch (err) {
        console.log(err);
        this.showMessageNotification({
          description: `Unable to update Space Score for the facility.`,
        });
      }
      this.updateDataLoading = false;
    },
  },
 
};
</script>

<style lang="scss" scoped>
.rm-tab-items {
  background-color: #f9fafb;
}
.bottom-action-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}
</style>
<style lang="scss">
.draggable-features {
  .hb-form-description-text-night-light {
    .hb-aviary-form-slot-wrapper {
      margin-bottom: 0px !important;
    }
  }
}
.space-scoring-wrapper{
  .custom-info-icon {
    i {
      margin-top: -3px;
    }
  }
}
</style>