<template>
  <div class="section-content pt-4">
    <template v-if="hasSpaceData">
      <rate-management-spaces
        @setPreviousData="setPreviousData($event)"
        :key="report_key"
      />
    </template>
    <div>
      <template v-if="!hasSpaceData">
        <RateManagementHome
          :previousData="previousSpaceGroupData"
          :key="report_key"
        />
      </template>
      <!-- <div v-show="!hasSpaceData && !wasInReturnState && !isReturnState">
        <RateManagementHome
          :previousData="previousSpaceGroupData"
        />
      </div> -->
      <!-- MAIN SECTION ENDS -->  
    </div>
  </div>
</template>
<script lang="js">
import RateManagementSpaces from "../utils/Spaces.vue";
import RateManagementHome from "./RateManagementHome.vue"
import { EventBus } from "../../../EventBus.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "RateManagement",
  data() {
    return {
      property: null,
      previousSpaceGroupData: {},
      // isReturnState: false,
      // wasInReturnState: false,
      report_key: 0,
    };
  },
  components: {
    RateManagementSpaces,
    RateManagementHome,
  },
  created(){
    EventBus.$on('refetch_data', () => this.fetchData());
  },
  computed: {
    ...mapGetters({
      spaceData: "revManStore/getSpaceData",
      selectedProperty: "revManStore/getSelectedProperty",
    }),
    hasSpaceData() {
      return Object.keys(this.spaceData).length;
    },
  },
  async destroyed() {
     await this.setProperty(null);
     EventBus.$off('refetch_data', () => this.fetchData());
  },
  methods: {
    ...mapActions({
      setProperty: "revManStore/setProperty",
    }),
    setPreviousData(data) {
      if(data?.propertyId) {
        // this.isReturnState = data.returnStatus
        this.previousSpaceGroupData = data
        // if (data.returnStatus == true) this.wasInReturnState = data.returnStatus
      }
    },
    fetchData(){
      this.report_key++;
    }
  },
};
</script>
<style lang="scss" scoped>
.rm-tab-items {
  background-color: #f9fafb;
}
.section-content {
  transition: none;
}
</style>