<template>
  <div class="value-pricing-wrapper">
    <!-- 
    - Displays messages in the following conditions
    - If no property is selected shows 'Select a facility to manage value based pricing configurations'
    - If Fetch API error occured, shows 'Oops! Something went wrong! Unable to fetch configurations for the selected facility.'
    -->
    <EmptyStateContainer
      v-if="showPlaceholder"
      :message="placeholderText"
      class="my-3"
    >
    </EmptyStateContainer>
    <RightSideNavigation
      v-else
      v-model="selectedSideTab"
      :menu-items="rightSideMenuItems"
    >
      <template v-slot:component>
        <ValueBasedPricing
          v-if="selectedSideTab == 'settings'"
          class="my-4"
        />
        <SpaceScoring
          v-if="selectedSideTab == 'scoring'"
          :property="property"
        />
      </template>
    </RightSideNavigation>
  </div>
</template>
<script>
//components
import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";
import ValueBasedPricing from "./ValueBasedPricing.vue";
import RightSideNavigation from "../utils/RightSideNavigation.vue";
import SpaceScoring from "./SpaceScoring.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ManageValueBasedPricing",
  data() {
    return {
      selectedSideTab: "settings",
      rightSideMenuItems: [
        {
          title: "Settings",
          id: "settings",
        },
        {
          title: "Space Scoring",
          id: "scoring",
        },
      ],
    };
  },
  components: {
    ValueBasedPricing,
    SpaceScoring,
    RightSideNavigation,
    EmptyStateContainer,
  },
  async mounted() {},
  computed: {
    ...mapGetters({
      property: "revManStore/getSelectedProperty",
      isRateManagementEnabled: "revManStore/isRateManagementEnabled",
      hasRolePermission: "authenticationStore/rolePermission",
    }),
    /**
     * A message for empty state container
     * message string may change according to the conditional check
     * @returns {String} message for placeholder
     */
    placeholderText() {
      let message =
        "Oops! Something went wrong! Unable to fetch configurations for the selected property.";
      if (!this.property)
        message =
          "Please select a Property to manage value based pricing configurations.";
      if (this.property && !this.isRateManagementEnabled)
        message = "Rate management is not enabled for this Property";
      if (this.property && !this.hasRolePermission("manage_value_pricing"))
        message =
          "You do not have the permission to access this page. Please contact your admin.";
      return message;
    },

    /**
     * Hide/show empty state container according return value
     * @returns {Boolean} A boolean value
     */
    showPlaceholder() {
      return (
        !this.property ||
        !this.isRateManagementEnabled ||
        !this.hasRolePermission("manage_value_pricing")
      );
    },
  },
  watch: {},
  methods: {},
};
</script>