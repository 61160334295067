<script>
import RateCard from "@/components/revenue_management/rate_management/RateCard.vue";
import RentCard from "@/components/revenue_management/rent_management/RentCard.vue";
import DescriptiveTags from "@/components/revenue_management/utils/DescriptiveTags.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    RateCard,
    RentCard,
    DescriptiveTags
  },
  data() {
    return {
      dialog1: false,
    };
  },
  computed: {
    ...mapGetters({
      getRatePlanById: "revManStore/getRatePlanById",
      getRentPlanById: "revManStore/getRentPlanById",
    }),
    hasPlan() {
      return ['rate','rent'].includes(this.params?.type) && this.params?.value !== `-` ? true : false
    },
    showPlanDataInfo() {
      return this.params.showPlanData ?? false
    },
    rateplan() {
      return this.getRatePlanById(this.params.data.spacegroup_rate_plan_id)
    },
    rentplan() {
      return this.getRentPlanById(this.params.data.tenant_rent_plan_id)
    },
    cellValue() {
      return this.params.value && this.hasPlan ? ` ${this.params.value}` : "";
    },
    descriptiveTagProperties() {
      return {
        tags: this[this.params.type + 'plan']?.tags ?? [],
        price_delta_type: this[this.params.type + 'plan']?.price_delta_type ?? ''
      }
    }
  },
  methods: {
    showPlan() {
        if (this.hasPlan && this.showPlanDataInfo) this.dialog1 =  true
    }
  }
};
</script>

<template>
  <div v-if="cellValue" :class="['plan-name-group', hasPlan && showPlanDataInfo ? 'has-click' : '']" @click.stop="showPlan">
    <DescriptiveTags :plan="descriptiveTagProperties" :conf="{ class: 'mr-0' }" small/>
    <hb-tooltip
      class="plancell-tooltip"
    >
      <template v-slot:item>
        <span
          v-text="cellValue"
        >
        </span>
      </template>
      <template v-slot:body>
        {{ cellValue }}
      </template>
    </hb-tooltip>
    <hb-icon v-if="hasPlan && showPlanDataInfo" small>
      mdi-information
    </hb-icon>
    <v-dialog
      v-model="dialog1"
      persistent
      max-width="520"
    >
      <v-card>
        <v-card-text class="pa-0">
          <RateCard
          v-if="params.type == 'rate'"
          :rate="rateplan"
          :type="'close'"
          @close="dialog1 = false"/>
          <RentCard
          v-else
          :rent-plan="rentplan"
          :type="'close'"
          @close="dialog1 = false"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.plan-name-group {
  display: flex;
  max-width: 100%;
  &.has-click {
    cursor: pointer;
  }
  span {
    padding: 1px;
    min-width: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
</style>