<template>
    <div class="value-pricing-wrapper">
        <EmptyStateContainer
        v-if="showPlaceholder"
        :message="placeholderText"
        class="my-3"
        >
        </EmptyStateContainer>
        <RightSideNavigation
            v-else
            v-model="selectedSideTab"
            :menu-items="rightSideMenuItems"
            >
            <template v-slot:component>
                <ManageRatePlans
                    v-if="selectedSideTab == 'rate-plans'"
                    class="my-4"
                />
                <!-- <RateCampaigns
                    v-if="selectedSideTab == 'promo-rates'"
                    :property="property"
                /> -->
            </template>
        </RightSideNavigation>
    </div>
</template>

<script>
    import EmptyStateContainer from "../../assets/EmptyStateContainer.vue";
    import RightSideNavigation from "../utils/RightSideNavigation.vue";
    import ManageRatePlans from "./ManageRatePlans.vue";
    import RateCampaigns from "./RateCampaigns.vue";
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: "LibrariesHome",
        data() {
            return {
                selectedSideTab: "rate-plans",
                rightSideMenuItems: [
                    {
                    title: "Rate Plan Library",
                    id: "rate-plans",
                    },
                    // {
                    // title: "Promo Rate Library",
                    // id: "promo-rates",
                    // },
                ],
            };
        },
        components: {
            EmptyStateContainer,
            RightSideNavigation,
            ManageRatePlans,
            RateCampaigns
        },
        computed:{
             ...mapGetters({
                property: "revManStore/getSelectedProperty",
                isRateManagementEnabled: "revManStore/isRateManagementEnabled",
                hasRolePermission: "authenticationStore/rolePermission",
            }),
            /**
             * A message for empty state container
             * message string may change according to the conditional check
             * @returns {String} message for placeholder
             */
            placeholderText() {
                let message =
                    "Oops! Something went wrong! Unable to fetch configurations for the selected property.";
                if (!this.property)
                    message =
                    "Please select a Property to manage value based pricing configurations.";
                if (this.property && !this.isRateManagementEnabled)
                    message = "Rate management is not enabled for this Property";
                if (this.property && !this.hasRolePermission("manage_value_pricing"))
                    message =
                    "You do not have the permission to access this page. Please contact your admin.";
                return message;
            },
            /**
             * Hide/show empty state container according return value
             * @returns {Boolean} A boolean value
             */
            showPlaceholder() {
                return (
                    !this.property ||
                    !this.isRateManagementEnabled ||
                    !this.hasRolePermission("manage_value_pricing")
                );
            },
        } 
    }

</script>
 