<template>
  <v-list 
    class="pt-0 scrollable-list-custom" 
    :style="{ height: draggableHeight + 'px' }"
    v-resize="setHeight" 
  >
    <draggable :list="list" @end="$emit('end', list)">
      <div v-for="(item, i) in list" :key="i">
        <v-list-item>
          <v-list-item-icon class="mr-2 my-2">
            <hb-btn icon tooltip="Drag" active-state-off>
              mdi-drag-custom
            </hb-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ item.name }}</strong>
              <span v-if="showValue(item.value)">: {{ item.value }}</span>
            </v-list-item-title>
          </v-list-item-content>
           <v-list-item-action >
            <v-checkbox class="px-10" style="width: 150px" v-model="item.show_in_filter_bar"></v-checkbox>
          </v-list-item-action>
          <v-list-item-action>
            <v-checkbox class="px-6" v-model="item.show_in_website"></v-checkbox>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </draggable>
  </v-list>
</template>
<script>
import Draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  name: "DraggableList",
  props: {
    list: {
      type: Array,
      required: true,
    },
    hideBooleanValue: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
    }),
    showLocalPropertySelector() {
      return this.properties?.length > 1;
    },
  },
  components: {
    Draggable,
  },
  data() {
    return {
      draggableHeight: 0,
    }
  },
  methods: {
    showValue(value) {
      if (!value) return false;
      if (this.hideBooleanValue)
        return !["Yes", "yes", "No", "no"].includes(value);
      return true;
    },
    setHeight() {
      let fixedComponets = 600;
      let propertySelector = this.showLocalPropertySelector ? 35 : 98;
      this.draggableHeight =
        window.innerHeight -
        fixedComponets +
        propertySelector;
    },
  },
};
</script>
<style lang="scss" scoped>
.scrollable-list-custom {
  height: 50vh;
  overflow-y: auto;
}
</style>